import {
  Button,
  FormControl,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { formatDate } from "../../../helpers/utilityFunctions";
import { CalendarIcon } from "../../icons";

interface DateRangeFilterProps {
  size?: "xs" | "sm" | (string & object) | "md" | "lg";
  startDate: string;
  endDate: string;
  setStartDate: React.Dispatch<React.SetStateAction<string>>;
  setEndDate: React.Dispatch<React.SetStateAction<string>>;
  onSubmit: (startDate: string, endDate: string) => void;
}

function DateRangeFilter({
  size,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  onSubmit,
}: DateRangeFilterProps) {
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

  const currentDate = new Date().toISOString().split("T")[0];

  const selectStart = formatDate(startDate) || "Jan 1, 1900";

  function handleStartDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    setStartDate(event.target.value);
  }

  function handleEndDateChange(event: React.ChangeEvent<HTMLInputElement>) {
    const selectedEndDate = event.target.value;
    if (selectedEndDate >= startDate) {
      setEndDate(selectedEndDate);
    }
  }

  function handleFilterTransactions() {
    const currentDate = new Date().toISOString().slice(0, 10);
    const endDateValue = endDate || currentDate;

    if (startDate) {
      onSubmit(startDate, endDateValue);
      setIsFilterModalOpen(false);
    }
    return startDate && endDateValue;
  }

  function handleClearFilter() {
    setStartDate("");
    setEndDate("");
    onSubmit(" ", " ");
    setIsFilterModalOpen(false);
  }

  function handleMenuClose() {
    if (startDate && endDate) {
      setIsFilterModalOpen(false);
    }
  }

  return (
    <FormControl>
      <Menu isOpen={isFilterModalOpen} onClose={handleMenuClose}>
        <MenuButton
          as={Button}
          leftIcon={<CalendarIcon boxSize="24px" />}
          variant="solid"
          colorScheme="transparent"
          color="#737983"
          size={size ? size : "sm"}
          fontWeight="400"
          backgroundColor="ash.100"
          borderRadius="14px"
          height="64px"
          // marginRight="40px"
          paddingLeft={10}
          paddingRight={12}
          onClick={() => setIsFilterModalOpen(!isFilterModalOpen)}
        >
          {startDate && endDate
            ? `${formatDate(startDate)} to ${formatDate(endDate)}`
            : `${selectStart} to ${formatDate(currentDate)}`}
        </MenuButton>
        <MenuList>
          <InputGroup>
            <Input
              type="date"
              placeholder="Start Date"
              value={startDate}
              onChange={handleStartDateChange}
              max={currentDate}
            />
          </InputGroup>

          <InputGroup>
            <Input
              type="date"
              placeholder="End Date"
              value={endDate}
              onChange={handleEndDateChange}
              max={currentDate}
              min={startDate}
            />
          </InputGroup>

          <Button
            onClick={handleFilterTransactions}
            colorScheme="grey"
            size="sm"
            width="100%"
            disabled={!startDate}
            color="#737983"
            border="1px solid #737983"
            marginTop=".5rem"
          >
            Apply
          </Button>
          <Button
            onClick={handleClearFilter}
            colorScheme="grey"
            size="sm"
            width="100%"
            color="#737983"
            border="1px solid #737983"
            marginTop=".5rem"
          >
            Clear
          </Button>
        </MenuList>
      </Menu>
    </FormControl>
  );
}

export default DateRangeFilter;
