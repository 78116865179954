import { Box } from "@chakra-ui/react";
import React from "react";

import { Page } from "../components/custom";
import TransactionTable from "../modules/VirtualAccounts/Transactions/TransactionTable";

export default function VirtualTransactionsPage() {
  return (
    <Page title="Virtual Transactions">
      <Box>
        <TransactionTable />
      </Box>
    </Page>
  );
}
