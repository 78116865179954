import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { clearStorage } from "../helpers/storage";

const refreshToken = localStorage.getItem("refresh-token");
const expiration = localStorage.getItem("expirationTime");

const getNewToken = async () => {
  if (refreshToken) {
    const data = await axios.post("/v1/auth/refresh", { refreshToken });
  }
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

const logoutUser = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
};

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response) {
      const { status } = error.response;

      switch (status) {
        case 403: {
          // window.location.reload();

          window.location.href = `${process.env.REACT_APP_BASE_URL}/app/forbidden`;
          break;
        }

        case 401: {
          if (
            error.response.data.errorCode === "TOKEN_EXPIRED" ||
            error.response.data.errorCode === "INVALID_TOKEN"
          ) {
            clearStorage();

            window.location.reload();

            return;
          }
        }
      }
    } else if (error.request) {
      //Do nothing
    } else {
      //Do nothing
    }
    throw error;
  }
);

const api = (axios: AxiosInstance) => {
  return {
    get: <T>(
      url: string,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> =>
      axios.get<T>(url, {
        ...config,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }),

    post: <T>(
      url: string,
      body: unknown,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> =>
      axios.post<T>(url, body, {
        ...config,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }),

    put: <T>(
      url: string,
      body: unknown,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> =>
      axios.put<T>(url, body, {
        ...config,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }),

    patch: <T>(
      url: string,
      body: unknown,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> =>
      axios.patch<T>(url, body, {
        ...config,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }),

    delete: <T>(
      url: string,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> =>
      axios.delete<T>(url, {
        ...config,
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }),
  };
};

export default api(instance);
