import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IGenericApiState } from "../../types/api";

interface IGetReportsRequest {
  limit?: number;
  page?: number;
  module:
    | "AccountStatement"
    | "Withdrawal"
    | "Deposit"
    | "Qr"
    | "VirtualAccount";
  type: "Monthly" | "Daily";
}

export interface IGetReportsData {
  id: string;
  name: string;
  module: string;
  type: string;
  url: string;
  date: string;
  createdAt: string;
  updatedAt: string;
}

interface IGetReportsResponse {
  status: string;
  message: string;
  data: {
    data: IGetReportsData[];
    dataSize: number;
    total: number;
  };
}

const initialState: IGenericApiState<IGetReportsResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const getReportsWithDownloadAsync = createAsyncThunk(
  "getReportsWithDownload",
  async (
    { limit, page, module, type }: IGetReportsRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `reports-history?limit=${limit}&page=${page}&module=${module}&type=${type}`
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<IGetReportsResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const reportsWithDownloadSlice = createSlice({
  name: "getReportsWithDownload",
  initialState,
  reducers: {
    resetGetReportsWithDownload: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(getReportsWithDownloadAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getReportsWithDownloadAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IGetReportsResponse;
      })
      .addCase(getReportsWithDownloadAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IGetReportsResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetReportsWithDownload } = reportsWithDownloadSlice.actions;

export default reportsWithDownloadSlice.reducer;
