import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IGenericApiState } from "../../types/api";

interface IGetVirtualTranscationRequest {
  pageSize?: number;
  pageNumber?: number;
  accountNumber?: string;
  startDate?: string;
  endDate?: string;
  notificationStatus?: string;
}

interface IGetVirtualTransactionData {
  transactionId: string;
  transactionReference: string;
  accountNumber: string;
  transactionType: string;
  amount: number;
  narration: string;
  transactionDate: string;
  notificationStatus: string;
}

interface IGetVirtualTransactionResponse {
  totalCount: number;
  status: string;
  message: string;
  statusCode: string;
  data: IGetVirtualTransactionData[];
}

const initialState: IGenericApiState<IGetVirtualTransactionResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const getVirtualTransactionAsync = createAsyncThunk(
  "getVirtualTransactions",
  async (
    {
      pageSize,
      pageNumber,
      accountNumber,
      startDate,
      endDate,
      notificationStatus,
    }: IGetVirtualTranscationRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await api.get(
        `virtual-account/transactions?page-size=${pageSize}&page-number=${pageNumber}&account-number=${accountNumber}&start-date=${startDate}&end-date=${endDate}&notification-status=${notificationStatus}`
      );
      return data;
    } catch (err) {
      const error = err as AxiosError<IGetVirtualTransactionResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const virtualTransactionFetchSlice = createSlice({
  name: "virtualTransaction/get",
  initialState,
  reducers: {
    resetGetVirtualTransaction: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builders) => {
    builders
      .addCase(getVirtualTransactionAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getVirtualTransactionAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IGetVirtualTransactionResponse;
      })
      .addCase(getVirtualTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IGetVirtualTransactionResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetGetVirtualTransaction } =
  virtualTransactionFetchSlice.actions;

export default virtualTransactionFetchSlice.reducer;
