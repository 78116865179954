import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { Page } from "../components/custom";
import PayoutDashboard from "../modules/VirtualAccounts/DailySweep/PayoutDashboard";

export default function DailySweepPage() {
  return (
    <Page title="Payout Dashboard">
      <Stack
        direction={{ base: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Box>
          <Heading
            as="h1"
            fontWeight={400}
            fontSize="1.5rem"
            lineHeight="133%"
            letterSpacing="-0.1px"
            mb={1}
            maxW="335px"
          >
            Daily Sweep
          </Heading>
          <Text color="gray.300" maxW="335px">
            Find the Daily Sweep on Bet9ja
          </Text>
        </Box>
      </Stack>
      <Box mt={5}>
        <PayoutDashboard />
      </Box>
    </Page>
  );
}
