// store/slices/depositIndicatorSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { getApiFormattedDate } from "../../helpers/utilityFunctions";

interface QRIndicatorSliceProps {
  page: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
}

interface IndicatorData {
  total: number;
  successful: number;
  pending: number;
  failed: number;
}

interface QRIndicatorState {
  data: IndicatorData;
  status: string;
  error: string;
}

const initialState: QRIndicatorState = {
  data: {
    total: 0,
    successful: 0,
    pending: 0,
    failed: 0,
  },
  status: "idle",
  error: "",
};

export const getQRIndicatorTransactionsAsync = createAsyncThunk(
  "indicator/getQRTransactionsData",
  async ({ startDate }: QRIndicatorSliceProps, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/qr/transactions/metrics?startDate=${startDate}&endDate=${getApiFormattedDate(
          new Date()
        )}`
      );
      return {
        total: response.data.data.totalTransactions,
        pending: response.data.data.totalPending,
        failed: response.data.data.totalFailed,
        successful: response.data.data.totalSuccessful,
      };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

const qrIndicatorSlice = createSlice({
  name: "qrIndicator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQRIndicatorTransactionsAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getQRIndicatorTransactionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload;
      })
      .addCase(getQRIndicatorTransactionsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

// export const { resetDepositIndicatorSlice } = depositIndicatorSlice.actions;

export default qrIndicatorSlice.reducer;
