import {
  Box,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Fragment } from "react";
import ReactPaginate from "react-paginate";

import { PillButton } from "../CustomButtons";
import {
  CustomTableSkeletonText,
  CustomTd,
  CustomTh,
  CustomThead,
  CustomTr,
} from "./";

interface TableProps {
  items: any[];
  details?: any[];
  columns: string[];
  isSuccess: boolean;
  isLoading: boolean;
  page?: number;
  onPageChange?: (page: number) => void;
  onViewTransaction?: (transaction: any) => void;
  limit?: number;
  total?: number;
  hidePagination?: boolean;
  actionButton?: boolean;
  actions?: {
    text: string;
    onClickFunction: (id: string) => void;
    variant?: "primary" | "secondary";
    viewCondition?: string[];
  }[];
}

export default function CustomTable({
  items,
  details,
  page = 1,
  onPageChange,
  columns,
  isLoading,
  isSuccess,
  onViewTransaction,
  limit = 10,
  total = 0,
  hidePagination = false,
  actionButton,
  actions,
}: Readonly<TableProps>) {
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;

  const handlePageChange = ({ selected }: { selected: number }) => {
    onPageChange?.(selected + 1);
  };

  if (items.length === 0 && !isLoading) {
    return (
      <Box py="80px" maxW="307px" mx="auto">
        <Box
          boxSize="200px"
          bg="gray.100"
          mb={2}
          mx="auto"
          borderRadius="50%"
        />
        <Text color="#000" textAlign="center" mb={1}>
          There is nothing to display yet.
        </Text>
        <Text
          color="gray.300"
          fontSize=".875rem"
          textAlign="center"
          lineHeight="171%"
        >
          Check again later
        </Text>
      </Box>
    );
  }

  return (
    <TableContainer>
      <Table size="sm" variant="unstyled">
        <CustomThead>
          <Tr>
            {columns.map((column) => (
              <CustomTh key={column}>
                <Text>{column}</Text>
              </CustomTh>
            ))}
            {onViewTransaction && <CustomTh key="view"></CustomTh>}
          </Tr>
        </CustomThead>
        <Tbody>
          {(items ?? [...Array(10)]).map((item: any, index: number) => {
            const properties = Object.keys(item);
            const updatedObj = { ...item };
            updatedObj["notificationStatus"] =
              item?.notificationStatus?.props?.children;

            return (
              <CustomTr key={index}>
                {properties?.map((property, index) => {
                  if (property === "key") {
                    return null;
                  }

                  return (
                    <Fragment key={index}>
                      <CustomTd densePadding key={property}>
                        <CustomTableSkeletonText isLoaded={!isLoading}>
                          <Box display="flex" gap={4} alignItems="center">
                            <span>{item[property]}</span>
                          </Box>
                        </CustomTableSkeletonText>
                      </CustomTd>
                    </Fragment>
                  );
                })}

                {onViewTransaction && (
                  <CustomTd densePadding>
                    <Box as="span" display="flex" alignItems="center" gap={3}>
                      <PillButton
                        background="#001419"
                        sx={{
                          borderRadius: "44px",
                          padding: "4px 24px",
                          height: "32px",
                          width: "75px",
                        }}
                        onClick={() => {
                          if (onViewTransaction) {
                            onViewTransaction(item.key);
                          }
                        }}
                      >
                        View
                      </PillButton>
                    </Box>
                  </CustomTd>
                )}
                {actionButton &&
                  actions &&
                  actions.map(
                    ({ text, onClickFunction, variant = "primary" }, index) => {
                      const isPrimaryVariant = variant === "primary";

                      return (
                        <CustomTd densePadding key={index}>
                          <Box display="flex" alignItems="center" gap={3}>
                            <PillButton
                              background={
                                isPrimaryVariant ? "#001419" : "ash.200"
                              }
                              sx={{
                                borderRadius: "44px",
                                padding: "4px 24px",
                                height: "32px",
                                width: "100%",
                                color: isPrimaryVariant ? "#fff" : "gray.500",
                              }}
                              onClick={() => {
                                onClickFunction(item.key);
                              }}
                            >
                              {text}
                            </PillButton>
                          </Box>
                        </CustomTd>
                      );
                    }
                  )}

                {actions &&
                  actions.map(
                    (
                      {
                        text,
                        onClickFunction,
                        variant = "primary",
                        viewCondition,
                      },
                      index
                    ) => {
                      const isPrimaryVariant = variant === "primary";
                      const contains =
                        viewCondition === undefined ||
                        (viewCondition !== undefined &&
                          viewCondition.every((condition) =>
                            Object.values(updatedObj).includes(condition)
                          ));

                      return (
                        contains && (
                          <CustomTd densePadding key={index}>
                            <Box display="flex" alignItems="center" gap={3}>
                              <PillButton
                                background={
                                  isPrimaryVariant ? "#001419" : "ash.200"
                                }
                                sx={{
                                  borderRadius: "44px",
                                  padding: "4px 24px",
                                  height: "32px",
                                  width: "100%",
                                  color: isPrimaryVariant ? "#fff" : "gray.500",
                                }}
                                onClick={() => {
                                  onClickFunction(item.key);
                                }}
                              >
                                {text}
                              </PillButton>
                            </Box>
                          </CustomTd>
                        )
                      );
                    }
                  )}
              </CustomTr>
            );
          })}
        </Tbody>
      </Table>
      <Box mt={10} mb={4} display="flex" justifyContent="center">
        <Flex
          sx={{
            ".pagination": {
              display: "flex",
              listStyle: "none",
            },
            ".active a": {
              color: "black",
              background: "#42E26F",
            },
            ".page-link": {
              color: "#ffffff",
              background: "#001419",
              padding: "10px 20px",
              border: "1px grey",
              marginTop: "2rem",
              marginRight: "10px",
              borderRadius: "10px",
            },
            ".page-link:hover": {
              background: "#42E26F",
              color: "black",
            },
            ".break-link": {
              marginRight: "10px",
            },
          }}
        >
          {!hidePagination && (
            <ReactPaginate
              breakLabel="..."
              nextLabel={">>"}
              breakLinkClassName="break-link"
              onPageChange={handlePageChange}
              pageRangeDisplayed={3}
              pageCount={Math.ceil(total / limit)}
              previousLabel={"<<"}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              disabledClassName="page-item-disabled"
            />
          )}
        </Flex>
      </Box>
    </TableContainer>
  );
}
