import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IApiResponseError, IGenericApiState } from "../../types/api";

interface IResendAllNotificationResponse {
  status: string;
  message: string;
  statusCode: string;
  data?: {
    numberOfResentNotifications: number;
  };
  errors: IApiResponseError[];
}

const initialState: IGenericApiState<IResendAllNotificationResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const resendAllNotificationAsync = createAsyncThunk(
  "resendAllNotification",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/virtual-account/transactions/resend-all-notifications`,
        {}
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IResendAllNotificationResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const resendAllNotificationSlice = createSlice({
  name: "resendAllNotification",
  initialState,
  reducers: {
    resendAllNotificationReset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendAllNotificationAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(resendAllNotificationAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IResendAllNotificationResponse;
      })
      .addCase(resendAllNotificationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IResendAllNotificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resendAllNotificationReset } =
  resendAllNotificationSlice.actions;

export default resendAllNotificationSlice.reducer;
