import { AbsoluteCenter, Box, Heading, Link } from "@chakra-ui/react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import { Page } from "../components/custom";

export default function ForbiddenPage() {
  const navigate = useNavigate();
  return (
    <Page title="Forbidden | Merchant Banking">
      <Box pos="relative" height="80vh">
        <AbsoluteCenter w="100%">
          <Heading fontSize={{ base: "1rem", md: "2rem" }} textAlign="center">
            403 | Forbidden
          </Heading>
          <Link
            as={RouterLink}
            to="/app/dashboard"
            color="primaryGreen"
            fontSize={{ base: ".875rem", md: "1rem" }}
            fontWeight={700}
            textAlign="center"
            mt={6}
            display="block"
            // onClick={() => navigate(-1)}
          >
            Go Back
          </Link>
        </AbsoluteCenter>
      </Box>
    </Page>
  );
}
