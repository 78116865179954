import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import axios from "../../api/axios";
import { IApiResponseError, IGenericApiState } from "../../types/api";

interface IAccountStatementRequest {
  startDate: string;
  endDate: string;
}

interface IAccountStatementResponse {
  status: boolean;
  message: string;
  errors?: IApiResponseError[];
  errorCode?: string;
}

const initialState: IGenericApiState<IAccountStatementResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const getAccountStatementAsync = createAsyncThunk(
  "accountStatement",
  async (
    { startDate, endDate }: IAccountStatementRequest,
    { rejectWithValue }
  ) => {
    try {
      const { data } = await axios.post("/account-statement", {
        startDate,
        endDate,
      });
      return data;
    } catch (err) {
      const error = err as AxiosError<IAccountStatementResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const getAccountStatementSlice = createSlice({
  name: "getAccountStatement",
  initialState,
  reducers: {
    getAccountStatementReset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAccountStatementAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getAccountStatementAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IAccountStatementResponse;
      })
      .addCase(getAccountStatementAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IAccountStatementResponse;
        state.error = action.error.message;
      });
  },
});

export const { getAccountStatementReset } = getAccountStatementSlice.actions;

export default getAccountStatementSlice.reducer;
