import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IApiResponseError, StatusType } from "../../types/api";
import { IUserToken } from "../../types/authentication";
import { showAlert } from "./appToastSlice";
import { signOut } from "./signOutSlice";

interface IRefreshTokenApiState<T> {
  data: T | null;
  status: StatusType;
  error?: string;
  message?: string;
  limit?: number;
}

interface IRefreshTokenResponse {
  status: boolean;
  message: string;
  data?: {
    user?: {
      id: string;
      email: string;
      role: string;
      firstName: string;
      lastName: string;
    };
  };
  token?: IUserToken;
  error?: IApiResponseError[];
}

const initialState: IRefreshTokenApiState<IRefreshTokenResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const refreshTokenAsync = createAsyncThunk(
  "auth/refreshToken",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const refreshToken = localStorage.getItem("refresh-token");

      const { data } = await api.post<IRefreshTokenResponse>("/auth/refresh", {
        refreshToken,
      });

      const expiryTime = new Date(
        Date.now().valueOf() + data?.data?.token?.expiresIn - 120000
      ).toString();

      localStorage.setItem("token", data?.data?.token?.accessToken);
      localStorage.setItem("refresh-token", data?.data?.token?.refreshToken);

      localStorage.setItem("expirationTime", expiryTime);

      return data;
    } catch (err) {
      const error = err as AxiosError<IRefreshTokenResponse>;

      dispatch(
        showAlert({
          status: "info",
          title: "Session Expired",
          description: "Please login to continue",
        })
      );

      dispatch(signOut());

      return rejectWithValue(error.response?.data);
    }
  }
);

const refreshTokenSlice = createSlice({
  name: "auth/refresh",
  initialState,
  reducers: {
    resetRefreshToken: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refreshTokenAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(refreshTokenAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(refreshTokenAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IRefreshTokenResponse;
        state.error = action.error.message;
      });
  },
});

export const { resetRefreshToken } = refreshTokenSlice.actions;

export default refreshTokenSlice.reducer;
