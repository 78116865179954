import { Box } from "@chakra-ui/react";

import QRPaymentsHistoryData from "./QRPaymentsHistoryData";

export default function QRPaymentsCard() {
  return (
    <Box
      background="#fff"
      border="1px solid #e0e3e6"
      borderRadius="24px"
      padding={6}
      minH="272px"
      height="100%"
    >
      <QRPaymentsHistoryData />
    </Box>
  );
}
