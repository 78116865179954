import {
  AbsoluteCenter,
  Box,
  Button,
  Center,
  Flex,
  Icon,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React from "react";

import useGetUIErrorMessage from "../../../../hooks/useGetUIErrorMessage";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  resendNotificationAsync,
  resendNotificationReset,
} from "../../../../store/slices/resendNotificationSlice";
import { ApprovalBoltIcon } from "../../../icons";
import { PrimaryButton } from "../../CustomButtons";
import { CustomModalFooter } from "../../CustomModal";
import { AnimatedLogo, StatusBox } from "../../Misc";

interface INotificationModal {
  id?: string;
  close?: () => void;
}

export const ResendNotificationModal = ({ id, close }: INotificationModal) => {
  const dispatch = useAppDispatch();

  const { status, data } = useAppSelector((state) => state.resendNotification);

  const handleResendNotification = (transactionId: string) => {
    dispatch(resendNotificationAsync(transactionId ?? ""));
  };

  const errorMessage = useGetUIErrorMessage({
    message: data?.message,
    errors: data?.errors,
  });

  if (status === "loading") {
    return (
      <Box minH="330px">
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (status === "success") {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="success" title="Notification Sent.">
              Notification Sent.! Your transaction has been re-initiated. Keep
              an eye on your dashboard for updates on successful completion.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            as={ModalCloseButton}
            w="full"
            top="initial"
            right="initial"
          >
            Dismiss
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  if (status === "failed") {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to send Nudge.">
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            onClick={() => dispatch(resendNotificationReset())}
            w="full"
          >
            Retry
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  return (
    <Box mb="-20">
      <Center>
        <Icon as={ApprovalBoltIcon} />
      </Center>
      <Text fontSize="1.5rem" fontWeight={500} textAlign="center">
        Resend Notification
      </Text>
      <Text
        fontSize="1.125rem"
        color="gray.200"
        textAlign="center"
        marginTop="12px"
        marginBottom="36px"
      >
        Resend notifications to retry failed transaction.
      </Text>
      <Flex mx="-7" gap={4} justifyContent="space-between">
        <Button
          sx={{
            bg: "#1A1C1F",
            color: "#ffffff",
            borderRadius: "12px",
            width: "214px",
            height: "48px",
            fontWeight: 0,
            fontSize: "1.125rem",
          }}
          onClick={() => {
            handleResendNotification(id ?? "");
          }}
        >
          Yes, Resend
        </Button>
        <Button
          sx={{
            bg: "#EBEDEF",
            borderRadius: "12px",
            width: "214px",
            height: "48px",
            fontWeight: 0,
            fontSize: "1.125rem",
            "&:hover": {
              bg: "#EBEDEF",
            },
          }}
          onClick={close}
        >
          {" "}
          Not now
        </Button>
      </Flex>
    </Box>
  );
};
