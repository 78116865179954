import { BoxProps, Td } from "@chakra-ui/react";

import CustomTableSkeletonText from "./CustomTableSkeletonText";

interface CustomTdProps extends BoxProps {
  children: React.ReactNode;
  isNumeric?: boolean;
  /**
   * @default false
   */
  densePadding?: boolean;
  /**
   * @default false
   */
  denserPadding?: boolean;
  /**
   * @default false
   */
  noPadding?: boolean;
  /**
   * @default false
   */
  isInactive?: boolean;
  /**
   * @default false
   */
  isLoading?: boolean;
}

export default function CustomTd({
  children,
  isNumeric,
  densePadding = false,
  denserPadding = false,
  noPadding = false,
  isInactive = false,
  isLoading = false,
  ...rest
}: CustomTdProps) {
  let paddingSize = "20px";

  if (densePadding) {
    paddingSize = "16px";
  } else if (denserPadding) {
    paddingSize = "12px";
  } else if (noPadding) {
    paddingSize = "0";
  }

  return (
    <Td
      isNumeric={isNumeric}
      paddingTop={paddingSize}
      paddingBottom={paddingSize}
      color={isInactive ? "gray.300" : "gray.500"}
      fontWeight="400"
      letterSpacing="-0.1px"
      fontSize=".75rem"
      lineHeight="200%"
      transition="all 0.2s"
      {...rest}
    >
      <CustomTableSkeletonText isLoaded={!isLoading}>
        {children}
      </CustomTableSkeletonText>
    </Td>
  );
}
