import { Box, Flex, Image, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

import Logo from "../../assets/images/9jaPay Logo_Horizontal.svg";
import MinimalNavbarCTA from "./MinimalNavbarCTA";

export default function MinimalNavbar() {
  return (
    <Box>
      <Flex
        bg="neutral.100"
        minH="80px"
        py={{ base: 2 }}
        px={{ base: 5, md: "44px" }}
        borderBottom={1}
        borderStyle="solid"
        borderColor="ash.200"
        align="center"
        justify="space-between"
      >
        <Link as={RouterLink} to="/">
          <Image src={Logo} w={{ base: "60%", md: "100%" }} />
        </Link>
        <MinimalNavbarCTA />
      </Flex>
    </Box>
  );
}
