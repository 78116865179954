import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { IApiState, IWithdrawalTransaction } from "../../types/api";

interface GetTransactionsValues {
  page: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
  search?: string;
}

const initialState: IApiState<IWithdrawalTransaction[]> = {
  data: undefined,
  status: "idle",
  error: "",
};

export const getTransactionsAsync = createAsyncThunk(
  "withdrawals/getTransactions",
  async (
    { page, limit = 10, startDate, endDate, search }: GetTransactionsValues,
    { rejectWithValue }
  ) => {
    let url = `/withdrawals/transactions?page=${page}`;

    if (limit) {
      url += `&limit=${limit}`;
    }
    if (startDate) {
      url += `&startDate=${startDate}`;
    }
    if (endDate) {
      url += `&endDate=${endDate}`;
    }
    if (search) {
      url += `&search=${encodeURIComponent(search)}`;
    }

    try {
      const { data } = await api.get(url);
      return data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

export const getWithdrawalTransactionsSlice = createSlice({
  name: "withdrawals/getTransactions",
  initialState,
  reducers: {
    resetGetWithdrawalTransactions: (state) => {
      state.data = undefined;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransactionsAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getTransactionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
        state.limit = action.payload.data.limit;
      })
      .addCase(getTransactionsAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetGetWithdrawalTransactions } =
  getWithdrawalTransactionsSlice.actions;

export default getWithdrawalTransactionsSlice.reducer;
