import { useAppSelector } from "../store/hooks";
import { selectAuthState } from "../store/slices/authSlice";

const mapPathToSlug = {
  "view-deposits": "deposits",
  "view-dashboard": "dashboard",
  "view-withdrawals": "withdrawals",
  "view-daily-reports": "daily-reports",
  "view-weekly-reports": "weekly-reports",
  "view-monthly-reports": "monthly-reports",
  "create-users": "create-users",
  "view-qr": "qr",
  "view-users": "users",
  "update-users": "update-users",
  "delete-users": "delete-users",
  "create-roles": "create-role",
  "view-roles": "roles",
  "update-roles": "update-roles",
};

export const usePermissions = (type?: string) => {
  const { user } = useAppSelector(selectAuthState);

  const userRole = user?.role;

  const permissions = userRole?.permissions?.reduce((acc, { slug }) => {
    const slugKey = mapPathToSlug[slug as keyof typeof mapPathToSlug];
    if (!slugKey) {
      return acc;
    }

    return {
      ...acc,
      [slugKey]: true,
    };
  }, {});

  return type
    ? user && permissions?.[type as keyof typeof permissions]
    : permissions;
};
