import {
  Avatar,
  Box,
  Flex,
  FlexProps,
  HStack,
  IconButton,
  Image,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

import Logo from "../../assets/images/9jaPay Logo_Horizontal.svg";
import AvatarImg from "../../assets/images/Avatar.svg";
import { BarsIcon, ChevronDownIcon } from "../../components/icons";
import { ElevationScroll } from "../../components/utils";
import { getLastPathTitle } from "../../helpers/utilityFunctions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { signOut } from "../../store/slices/signOutSlice";
import { DRAWER_WIDTH } from "./AppSidebar";

interface AppHeaderProps extends FlexProps {
  onOpen: () => void;
}

const AppHeader = ({ onOpen, ...rest }: AppHeaderProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSignOut = () => {
    dispatch(signOut());
    navigate("/");
  };

  const navigateSettings = () => {
    navigate("/app/settings");
  };

  const { user } = useAppSelector((state) => state.auth);

  return (
    <ElevationScroll>
      <Flex
        as="header"
        width={{ base: "100%", md: `calc(100% - ${DRAWER_WIDTH + 1}px)` }}
        px={{ base: 4, md: "44px" }}
        ml={{ base: 0, md: DRAWER_WIDTH + 1 }}
        height="20"
        alignItems="center"
        justifyContent="space-between"
        {...rest}
        position="fixed"
        zIndex="1"
        background="#fafafa"
      >
        <IconButton
          display={{ base: "flex", md: "none" }}
          onClick={onOpen}
          variant="ghost"
          aria-label="open menu"
          icon={<BarsIcon w="24px" />}
        />

        <LinkBox display={{ base: "flex", md: "none" }} justifyContent="center">
          <LinkOverlay as={RouterLink} to="/app">
            <Image src={Logo} w="60%" />
          </LinkOverlay>
        </LinkBox>

        <Text
          display={{ base: "none", md: "block" }}
          fontFamily="Aeonik"
          fontWeight={500}
        >
          {getLastPathTitle(pathname)}
        </Text>

        <HStack spacing={{ base: "0", md: "3" }}>
          <Flex alignItems={"center"}>
            <Menu>
              <MenuButton
                py={2}
                transition="all 0.3s"
                _focus={{ boxShadow: "none" }}
              >
                <HStack>
                  <Avatar src={AvatarImg} boxSize="32px" />

                  <Text fontSize=".875rem" fontWeight={500}>
                    {user?.firstName}
                  </Text>

                  <Box display={{ base: "none", md: "flex" }}>
                    <ChevronDownIcon w="10px" />
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList bg="white" borderColor="gray.100">
                {/* <MenuItem fontSize="1rem" lineHeight="1.3125rem">
                  Profile
                </MenuItem> */}
                <MenuItem
                  fontSize="1rem"
                  lineHeight="1.3125rem"
                  onClick={navigateSettings}
                >
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem
                  fontSize="1rem"
                  lineHeight="1.3125rem"
                  onClick={handleSignOut}
                >
                  Sign out
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
    </ElevationScroll>
  );
};

export default AppHeader;
