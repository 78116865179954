import {
  Box,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Select,
  Stack,
  Text,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { ExportToCsv } from "export-to-csv";
import React, { useEffect, useState } from "react";

import axios from "../../../api/axios";
import {
  CustomModal,
  PillButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/custom";
import DateRangeFilter from "../../../components/custom/CustomDateRange/CustomDateRangeFilter";
import CustomTable from "../../../components/custom/CustomTable/CustomTable";
import RefundTransactionModal from "../../../components/custom/VirtualAccounts/Transactions/RefundTransactionModal";
import { ResendNotificationModal } from "../../../components/custom/VirtualAccounts/Transactions/ResendNotificationModal";
import { CloseIcon, SearchIcon } from "../../../components/icons";
import {
  formatCamelCaseToWords,
  formatDate,
  formatKoboToNairaAndKobo,
  formatNumber,
  formatStringToSeeMore,
  getSentenceCase,
  getTitleCase,
} from "../../../helpers/utilityFunctions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getVirtualTransactionAsync } from "../../../store/slices/getVirtualTransactionsSlice";
import { refundTransactionReset } from "../../../store/slices/refundTransactionSlice";
import {
  resendAllNotificationAsync,
  resendAllNotificationReset,
} from "../../../store/slices/resendAllNotificationsSlice";
import { resendNotificationReset } from "../../../store/slices/resendNotificationSlice";

export interface ITransactionData {
  transactionId: string;
  transactionReference: string;
  accountNumber: string;
  transactionType: string;
  amount: number;
  narration: string;
  transactionDate: string;
  notificationStatus: string;
}

export default function TransactionTable() {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const { status, data } = useAppSelector(
    (state) => state.getVirtualTransactions
  );

  const {
    status: resendAllNotificationStatus,
    data: resendAllNotificationData,
  } = useAppSelector((state) => state.resendAllNotifications);

  const { status: refundStatus } = useAppSelector(
    (state) => state.refundTransaction
  );

  const transactions = data?.data || [];

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [page, setPage] = useState(1);

  const [numTransactions, setNumTransactions] = useState(10);
  const [notStatus, setNotStatus] = useState("");

  const [accountNumber, setAccountNumber] = useState("");

  const [selectedTransaction, setSelectedTransaction] = useState<string>("");
  const [isExportLoading, setIsExportLoading] = useState<boolean>(false);

  const [modalOpenStates, setModalOpenStates] = useState<{
    [key: string]: boolean;
  }>({});

  const [selectedTransactionDetails, setSelectedTransactionDetails] =
    useState<ITransactionData>();

  const [refundTransaction, setRefundTransaction] = useState<string>("");

  const [refundModalOpenStates, setRefundModalOpenStates] = useState<{
    [key: string]: boolean;
  }>({});

  useEffect(() => {
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        accountNumber,
        pageNumber: page,
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, page, numTransactions, notStatus]);

  //resend notification modal functions
  const openModal = (id: string) => {
    setModalOpenStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const closeModal = (id: string) => {
    setModalOpenStates((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleModalClose = (id: string) => {
    closeModal(id);
    dispatch(resendNotificationReset());
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        accountNumber,
        pageNumber: page,
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
  };

  //refund modal functions
  const openRefundModal = (id: string) => {
    setRefundModalOpenStates((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const closeRefundModal = (id: string) => {
    setRefundModalOpenStates((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const handleRefundModalClose = (id: string) => {
    closeRefundModal(id);
    dispatch(refundTransactionReset());
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        accountNumber,
        pageNumber: page,
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
    if (refundStatus === "success") {
      let count = 0;
      const intervalId = setInterval(() => {
        if (count === 3) {
          clearInterval(intervalId);
          return;
        }
        dispatch(
          getVirtualTransactionAsync({
            startDate,
            endDate,
            accountNumber,
            pageNumber: page,
            pageSize: numTransactions,
            notificationStatus: notStatus,
          })
        );
        count += 1;
      }, 180000);
    }
  };

  const handlePageChange = (pageNumber: number) => {
    setPage(pageNumber);
  };

  //conditions for resending all notifications
  const notification = (
    message: string,
    status: "info" | "warning" | "success" | "error" | "loading" | undefined,
    title?: string
  ) => {
    toast({
      title: title,
      description: message,
      status: status,
      duration: 3000,
      position: "top-right",
      isClosable: true,
    });
  };

  //successful conditions
  if (resendAllNotificationStatus === "success") {
    const numOfNotification =
      resendAllNotificationData?.data?.numberOfResentNotifications;
    notification(
      `${numOfNotification} ${
        numOfNotification === 1 ? "notification" : "notifications"
      } resent`,
      "success",
      "Notification Resent"
    );
    dispatch(resendAllNotificationReset());
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        accountNumber,
        pageNumber: page,
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
  }

  //failed conditions
  if (resendAllNotificationStatus === "failed") {
    notification(`${resendAllNotificationData?.message}`, "error");
    dispatch(resendAllNotificationReset());
  }

  //Search related functions
  //makes the request with the value of the search field
  const handleSearchTransaction = () => {
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        accountNumber,
        pageNumber: 1,
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
  };

  //resets the search field
  const handleCancelSearch = () => {
    setAccountNumber("");
    dispatch(
      getVirtualTransactionAsync({
        startDate,
        endDate,
        pageNumber: 1,
        accountNumber: "",
        pageSize: numTransactions,
        notificationStatus: notStatus,
      })
    );
  };

  //function to export all transactions
  const handleExportToCSV = async () => {
    setIsExportLoading(true);
    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: true,
      showTitle: false,
      filename: `Virtual_Accounts_Transactions_${new Date().getTime()}`,
      title: `Virtual_Accounts_Transactions_${new Date().getTime()}`,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };

    const response = await axios
      .get(
        `virtual-account/transactions?page-size=${
          data?.totalCount
        }&page-number=${1}&account-number=${accountNumber}&start-date=${startDate}&end-date=${endDate}&notification-status=${notStatus}`
      )
      .then((response) => {
        response.status === 200 && setIsExportLoading(false);
        return response;
      });

    const exportedTransaction = response.data.data;

    const dataToExport = exportedTransaction?.map(
      (transaction: ITransactionData) => ({
        "Transaction Id": transaction.transactionId,
        "Transaction Reference": transaction.transactionReference,
        "Account Number": transaction.accountNumber,
        "Total Amount": transaction.amount
          ? formatNumber(transaction.amount / 100).formattedStringWithKobo
          : "",
        "Transaction Type": transaction.transactionType,
        Date: transaction.transactionDate
          ? formatDate(transaction.transactionDate)
          : "",
        Narration: transaction.narration,
        "Notification Status": transaction.notificationStatus,
      })
    );
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(dataToExport ?? "");
  };

  //Function to open the modal for resending notification
  const handleSendPrompt = (id: string) => {
    openModal(id);
    setSelectedTransaction(id);
  };

  //function to refund the transaction
  const handleRefundTransaction = (id: string) => {
    openRefundModal(id);
    setRefundTransaction(id);
    setSelectedTransactionDetails(
      transactions?.filter(
        (transaction) => transaction.transactionId === id
      )?.[0]
    );
  };

  //function to filter the transactions by start and end dates
  const handleFilterTransactions = (startDate: string, endDate: string) => {
    dispatch(
      getVirtualTransactionAsync({
        accountNumber,
        startDate,
        endDate,
        pageSize: numTransactions,
        pageNumber: 1,
        notificationStatus: notStatus,
      })
    );
  };

  //function to resend all failed notifications
  const handleResendAllNotifications = () => {
    dispatch(resendAllNotificationAsync());
  };

  //function to change the number of rows per table indirectly the number of transactions displayed per page
  const handleNumTransactionsChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = parseInt(e.target.value);
    setNumTransactions(value);
    setPage(1);
  };

  //function to handle notification status filter
  const handleFilterNotificationStatus = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const value = e.target.value;
    setNotStatus(value);
    setPage(1);
  };

  //function to render the formatted narration on the table
  const renderNarration = (narration: string) => {
    if (narration.length > 20) {
      return (
        <Tooltip
          hasArrow
          placement="bottom"
          label={
            <Box padding="16px 24px">
              <Text fontWeight={400} fontSize="0.75rem" color="#D4D9DD">
                {narration}
              </Text>
            </Box>
          }
          bg="#000"
          borderRadius="18px"
        >
          <Text>{formatStringToSeeMore(narration, 5)}...</Text>
        </Tooltip>
      );
    }
    return narration;
  };

  const renderNotificationStatus = (status: string) => {
    return (
      <PillButton
        background={
          status === "Failed"
            ? "#f5d7d7"
            : status === "Success"
            ? "#e6fcec"
            : "#f7ecd2"
        }
        sx={{
          color:
            status === "Failed"
              ? "#ad0e13"
              : status === "Success"
              ? "#07912c"
              : "#d69e18",
        }}
        size="sm"
      >
        {status}
      </PillButton>
    );
  };

  const formattedTransactions = transactions.map((transaction) => ({
    key: transaction.transactionId,
    transactionId: transaction.transactionId,
    accountNumber: transaction.accountNumber,
    amount: `₦ ${formatKoboToNairaAndKobo(transaction.amount)}`,
    transactionDate: formatDate(transaction.transactionDate),
    transactionType: getTitleCase(getSentenceCase(transaction.transactionType)),
    narration: renderNarration(transaction.narration),
    notificationStatus: renderNotificationStatus(
      transaction.notificationStatus === "AwaitingManualRefund" ||
        transaction.notificationStatus === "ProcessingRefund"
        ? formatCamelCaseToWords(transaction.notificationStatus)
        : transaction.notificationStatus
    ),
  }));

  return (
    <>
      <section>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Heading
              as="h1"
              fontWeight={400}
              fontSize="1.5rem"
              lineHeight="133%"
              letterSpacing="-0.1px"
              mb={1}
              maxW="335px"
            >
              Transactions
            </Heading>
            <Text color="gray.300" maxW="335px">
              Find the Virtual Transactions made on Bet9ja
            </Text>
          </Box>
          <Stack direction={{ base: "column", lg: "row" }} spacing={2}>
            <SecondaryButton
              onClick={handleResendAllNotifications}
              isLoading={resendAllNotificationStatus === "loading"}
            >
              Resend All Failed Notifications
            </SecondaryButton>
            <PrimaryButton
              onClick={handleExportToCSV}
              isLoading={isExportLoading}
              isDisabled={status !== "success"}
              marginBottom={2}
            >
              Export Transactions
            </PrimaryButton>
          </Stack>
        </Stack>
      </section>
      <Box
        background="#fff"
        border="1px solid #e0e3e6"
        borderRadius="24px"
        padding={{ md: 6 }}
        minH="272px"
        height="100%"
        mt={5}
      >
        <Stack
          justifyContent="space-between"
          mb="28px"
          direction={{ base: "column", lg: "row" }}
          width="100%"
        >
          <Stack
            spacing={4}
            direction={{ base: "column", xl: "row" }}
            // border="1px solid #e0e3e6"
            borderRadius="14px"
            padding="8px"
            height="fit-content"
          >
            <Text alignSelf={{ xl: "center" }}>Filters:</Text>
            <Box>
              <Select
                value={notStatus}
                onChange={handleFilterNotificationStatus}
                size="xs"
                placeholder="Any Status"
                width={{ lg: "300px", xl: "200px" }}
                sx={{ height: "64px" }}
              >
                {/* <option value={" "}>All</option> */}
                <option value={"Success"}>Success</option>
                <option value={"Pending"}>Pending</option>
                <option value={"Failed"}>Failed</option>
                <option value={"AwaitingManualRefund"}>
                  Awaiting Manual Refund
                </option>
                <option value={"ProcessingRefund"}>Processing Refund</option>
                <option value={"Refunded"}>Refunded</option>
              </Select>
            </Box>
            <Box>
              <FormControl>
                <DateRangeFilter
                  startDate={startDate}
                  endDate={endDate}
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  onSubmit={handleFilterTransactions}
                />
              </FormControl>
            </Box>
            <Flex
              direction={{ base: "column", md: "row" }}
              justifyContent={{ base: "space-between", md: "initial" }}
              alignItems="center"
              gap={2}
              sx={{
                border: "1px solid  #e0e3e6",
                borderRadius: "14px",
                padding: "4px",
              }}
            >
              <FormControl id="search-transactions">
                <InputGroup>
                  <InputLeftElement pointerEvents="none" pt="7" pb="2.5">
                    <SearchIcon />
                  </InputLeftElement>
                  <Input
                    variant="unstyled"
                    placeholder="Account Number"
                    value={accountNumber}
                    onChange={(e) => setAccountNumber(e.target.value)}
                    id="search-transactions-input"
                    sx={{
                      backgroundColor: "ash.100",
                      borderRadius: "44px",
                      height: "40px",
                      paddingY: "0",
                    }}
                  />
                  {accountNumber && (
                    <InputRightElement pt="7" pb="2.5">
                      <CloseIcon onClick={handleCancelSearch} />
                    </InputRightElement>
                  )}
                </InputGroup>
              </FormControl>
              <PrimaryButton onClick={handleSearchTransaction}>
                Search
              </PrimaryButton>
            </Flex>
          </Stack>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            //   alignItems={{ lg: "center" }}
            gap={2}
          >
            <Box display="flex" gap={4} alignItems="baseline">
              <Text fontWeight={500} lineHeight="250%">
                {transactions.length ?? 0}{" "}
                {transactions.length === 1 ? "transaction" : "transactions"}{" "}
              </Text>
              <Select
                value={numTransactions}
                onChange={handleNumTransactionsChange}
                size="sm"
                width="70px"
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={20}>20</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </Select>
            </Box>
          </Box>
        </Stack>
        <Box mt={8}>
          <Box>
            <CustomTable
              columns={[
                "Transaction ID",
                "Account Number",
                "Amount",
                "Transaction Date",
                "Transaction Type",
                "Narration",
                "Notification Status",
                "",
              ]}
              items={formattedTransactions}
              isLoading={status === "loading"}
              isSuccess={Boolean(status === "idle" && transactions)}
              page={page}
              onPageChange={handlePageChange}
              limit={numTransactions}
              total={data?.totalCount ?? 0}
              actions={[
                {
                  text: "Resend Notification",
                  onClickFunction: (id: string) => handleSendPrompt(id),
                  viewCondition: ["Failed"],
                },
                {
                  text: "Refund",
                  onClickFunction: (id: string) => handleRefundTransaction(id),
                  variant: "secondary",
                  viewCondition: ["Failed", "Credit"],
                },
              ]}
            />
          </Box>
          <CustomModal
            isOpen={modalOpenStates[selectedTransaction]}
            onClose={() => handleModalClose(selectedTransaction ?? "")}
          >
            <ResendNotificationModal
              id={selectedTransaction}
              close={() => handleModalClose(selectedTransaction ?? "")}
            />
          </CustomModal>
          <CustomModal
            isOpen={refundModalOpenStates[refundTransaction]}
            onClose={() => handleRefundModalClose(refundTransaction ?? "")}
          >
            <RefundTransactionModal
              id={refundTransaction}
              close={() => handleRefundModalClose(refundTransaction ?? "")}
              transactionDetails={selectedTransactionDetails}
            />
          </CustomModal>
        </Box>
      </Box>
    </>
  );
}
