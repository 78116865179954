import "./assets/fonts/Switzer/Fonts/WEB/css/switzer.css";
import "./assets/fonts/Aeonik/aeonik.css";

import { ChakraProvider, useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import AppToast from "./components/notification/AppToast";
import { ErrorBoundary, ScrollToTop } from "./components/utils";
import Routes from "./routes";
import { useAppDispatch } from "./store/hooks";
import theme from "./theme";

function App() {
  const navigate = useNavigate();

  const expirationTime = localStorage.getItem("expirationTime");

  const toast = useToast();
  const dispatch = useAppDispatch();

  return (
    <ChakraProvider theme={theme}>
      <ErrorBoundary>
        <ScrollToTop />
        <AppToast />
        <Routes />
      </ErrorBoundary>
    </ChakraProvider>
  );
}

export default App;
