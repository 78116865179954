import { AbsoluteCenter, Box, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { Page, SecondaryButton } from "../components/custom";

export default function ErrorPage() {
  const navigate = useNavigate();

  const refreshPage = () => {
    navigate(0);
  };
  return (
    <Page title="Oops | Merchant Banking">
      <Box pos="relative" height="100%">
        <AbsoluteCenter w="100%">
          <Heading fontSize={{ base: "1rem", md: "2rem" }} textAlign="center">
            Oops! An error occurred on our end, please try again.
          </Heading>
          <SecondaryButton
            onClick={refreshPage}
            display="block"
            mx="auto"
            my={6}
            px="60px"
          >
            Retry
          </SecondaryButton>
        </AbsoluteCenter>
      </Box>
    </Page>
  );
}
