import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";

import appToastReducer from "./slices/appToastSlice";
import authReducer from "./slices/authSlice";
import getDailyReportsDataReducer from "./slices/dailyReportsSlice";
import depositIndicatorReducer from "./slices/depositIndicatorSlice";
import getDepositsTransactionsReducer from "./slices/depositTransactionSlice";
import getFundingReportsReducer from "./slices/fundingReportsSlice";
import getAccountStatementReducer from "./slices/generateAccountStatementSlice";
import getVirtualTransactionsReducer from "./slices/getVirtualTransactionsSlice";
import getHourlyReportsDataReducer from "./slices/hourlyReportsSlice";
import indicatorReducer from "./slices/indicatorTransactionSlice";
import passwordResetCompleteReducer from "./slices/passwordResetCompleteSlice";
import passwordResetRequestReducer from "./slices/passwordResetRequestSlice";
// import editRoleReducer from "./slices/editRoleSlice";
// import newRoleReducer from "./slices/newRoleSlice";
import permissionsReducer from "./slices/permissionsSlice";
import qrIndicatorReducer from "./slices/qrIndicatorSlice";
import getQRPaymentsReducer from "./slices/qrPaymentSlice";
import refreshTokenReducer from "./slices/refreshTokenSlice";
import refundTransactionReducer from "./slices/refundTransactionSlice";
import reportsWithDownloadSliceReducer from "./slices/reportsWithDownloadSlice";
import resendAllNotificationsReducer from "./slices/resendAllNotificationsSlice";
import resendNotificationsReducer from "./slices/resendNotificationSlice";
import rolesReducer from "./slices/roleSlice";
import usersReducer from "./slices/userSlice";
import withdrawalIndicatorReducer from "./slices/withdrawalIndicatorSlice";
import getWithdrawalTransactionsReducer from "./slices/withdrawalTransactionSlice";

export const store = configureStore({
  reducer: {
    getDepositsTransactions: getDepositsTransactionsReducer,
    getWithdrawalTransactions: getWithdrawalTransactionsReducer,
    getQRPayments: getQRPaymentsReducer,
    auth: authReducer,
    // newRole: newRoleReducer,
    // editRole: editRoleReducer,
    roles: rolesReducer,
    users: usersReducer,
    permissions: permissionsReducer,
    passwordResetRequest: passwordResetRequestReducer,
    passwordResetComplete: passwordResetCompleteReducer,
    indicatorTransactionsData: indicatorReducer,
    depositIndicatorTransactionsData: depositIndicatorReducer,
    withdrawalIndicatorTransactionsData: withdrawalIndicatorReducer,
    qrIndicatorTransactionsData: qrIndicatorReducer,
    getHourlyReportsData: getHourlyReportsDataReducer,
    getDailyReportsData: getDailyReportsDataReducer,
    getFundingReport: getFundingReportsReducer,
    appToast: appToastReducer,
    refreshToken: refreshTokenReducer,
    getVirtualTransactions: getVirtualTransactionsReducer,
    resendNotification: resendNotificationsReducer,
    resendAllNotifications: resendAllNotificationsReducer,
    getAccountStatement: getAccountStatementReducer,
    refundTransaction: refundTransactionReducer,
    getReportsWithDownload: reportsWithDownloadSliceReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
