import { Link } from "@chakra-ui/react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

const MinimalNavbarCTA: React.FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1);
  };

  const ctaComponent = () => {
    switch (true) {
      case pathname === "/sign-up":
        return (
          <Link
            as={RouterLink}
            to="/"
            letterSpacing={-0.12}
            lineHeight="1.4375rem"
          >
            Sign In
          </Link>
        );

      case pathname.startsWith("/sign-up/"):
        return (
          <Link
            onClick={handleGoBack}
            letterSpacing={-0.12}
            lineHeight="1.4375rem"
          >
            Go Back
          </Link>
        );

      default:
        return null;
    }
  };

  return <>{ctaComponent()}</>;
};

export default MinimalNavbarCTA;
