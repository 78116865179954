import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { StatusType } from "../../types/api";
import { IReport } from "../../types/reports";

interface GetDailyReportsValues {
  page: number;
  limit?: number;
}

interface IHourlyReportApiState<T> {
  data: T | null;
  status: StatusType;
  error?: string;
  message?: string;
  limit?: number;
}

const initialState: IHourlyReportApiState<IReport[]> = {
  data: null,
  status: "idle",
  error: "",
};

export const getDailyReportsDataAsync = createAsyncThunk(
  "reports/daily",
  async ({ page, limit }: GetDailyReportsValues, { rejectWithValue }) => {
    const url = "/reports";

    try {
      const { data } = await api.post(url, { page, limit, requestType: 2 });
      return data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

export const getDailyReportsDataSlice = createSlice({
  name: "reports/getDailyReportsData",
  initialState,
  reducers: {
    resetGetDailyReportsData: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDailyReportsDataAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getDailyReportsDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data;
        state.limit = action.payload.data.limit;
      })
      .addCase(getDailyReportsDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetGetDailyReportsData } = getDailyReportsDataSlice.actions;

export default getDailyReportsDataSlice.reducer;
