import { lazy } from "react";
import { RouteObject } from "react-router-dom";

import { MinimalLayout } from "../layouts";
import ComingSoonPage from "../pages/ComingSoon";
import Loadable from "./Loadable";
import PublicRoute from "./PublicRoute";

// Lazy Imports
const LoginPage = Loadable(
  lazy(() => import("../pages/onboardingPages/LoginPage"))
);
const SignUpPage = Loadable(
  lazy(() => import("../pages/onboardingPages/SignUpPage"))
);
const PasswordResetPage = Loadable(
  lazy(() => import("../pages/onboardingPages/PasswordResetPage"))
);
const NotFoundPage = Loadable(lazy(() => import("../pages/NotFoundPage")));
const CreateNewAccountPage = Loadable(
  lazy(() => import("../pages/onboardingPages/CreateNewAccountPage"))
);
const SignUpOTPPage = Loadable(
  lazy(() => import("../pages/onboardingPages/SignUpOTPPage"))
);
const VideoVerificationPage = Loadable(
  lazy(() => import("../pages/onboardingPages/VideoVerificationPage"))
);
const CreatePasswordPage = Loadable(
  lazy(() => import("../pages/onboardingPages/CreatePasswordPage"))
);
const CreateTransactionPinPage = Loadable(
  lazy(() => import("../pages/onboardingPages/CreateTransactionPinPage"))
);
const ProcessingProfilePage = Loadable(
  lazy(() => import("../pages/onboardingPages/ProcessingProfilePage"))
);
const WelcomePage = Loadable(
  lazy(() => import("../pages/onboardingPages/WelcomePage"))
);
const InvitedTeamMemberPage = Loadable(
  lazy(() => import("../pages/onboardingPages/InvitedTeamMemberPage"))
);

// Route Object
const AuthenticationRoutes: RouteObject = {
  path: "/",
  element: (
    <PublicRoute>
      <MinimalLayout />
    </PublicRoute>
  ),
  children: [
    {
      index: true,
      element: <LoginPage />,
    },
    {
      path: "/sign-up",
      children: [
        {
          index: true,
          element: <SignUpPage />,
        },
        {
          path: "/sign-up/create-new-account",
          element: <CreateNewAccountPage />,
        },
        {
          path: "/sign-up/otp",
          element: <SignUpOTPPage />,
        },
        {
          path: "/sign-up/video-verification",
          element: <VideoVerificationPage />,
        },
        {
          path: "/sign-up/create-password",
          element: <CreatePasswordPage />,
        },
        {
          path: "/sign-up/create-transaction-pin",
          element: <CreateTransactionPinPage />,
        },
        {
          path: "/sign-up/processing-profile",
          element: <ProcessingProfilePage />,
        },
        {
          path: "/sign-up/welcome",
          element: <WelcomePage />,
        },
      ],
    },
    {
      path: "/invited-user",
      element: <InvitedTeamMemberPage />,
    },
    {
      path: "/password-reset",
      element: <PasswordResetPage />,
    },
    {
      path: "*",
      element: <NotFoundPage />,
    },
  ],
};

export default AuthenticationRoutes;
