import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import React from "react";

import { Page } from "../components/custom";
import WithdrawalTransactionsCard from "../modules/Withdrawals/WithdrawalTransactionsCard";

export default function WithdrawalHistoryPage() {
  return (
    <Page title="Withdrawals History">
      <section>
        <Stack
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="baseline"
        >
          <Box>
            <Heading
              as="h1"
              fontWeight={400}
              fontSize="1.5rem"
              lineHeight="133%"
              letterSpacing="-0.1px"
              mb={1}
              maxW="335px"
            >
              Withdrawals History
            </Heading>
            <Text color="gray.300" maxW="335px">
              Find the Withdrawals made on Bet9ja
            </Text>
          </Box>
        </Stack>
      </section>
      <Box mt={5}>
        <WithdrawalTransactionsCard />
      </Box>
    </Page>
  );
}
