const colors = {
  ash: {
    100: "#f9fafa",
    200: "#ebedef",
    400: "#d4d9dd",
    600: "#7b828e",
  },
  gray: {
    100: "#c9cfd4",
    200: "#7b828e",
    300: "#656b76",
    400: "#50555E",
    500: "#1a1c1f",
    600: "#ebedef",
  },
  green: {
    100: "#ebfff0",
    150: "#d0f7d4",
    200: "#a8eeaa",
    500: "#14cc52",
    600: "#00c707",
    700: "#009f06",
  },
  red: {
    100: "#ffeceb",
    500: "#e54839",
    600: "#db4343",
    700: "#c63434",
  },
  neutral: {
    100: "#fff",
  },
  primaryGreen: "#14cc52",
};

export default colors;
