import { Flex, FlexProps, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface NavItemProps extends FlexProps {
  item: {
    title: string;
    path?: string;
  };
  active: (path?: string) => boolean;
}

export default function SubnavItem({ item, active, ...rest }: NavItemProps) {
  const isActiveNavItem = active(item.path);

  const activeNavStyle: React.CSSProperties = {
    borderLeft: "4px",
    borderColor: "#1a1c1f",
  };

  const activeTitleStyle: React.CSSProperties = {
    fontWeight: "700",
  };

  return (
    <>
      {item.path && (
        <Link as={RouterLink} to={item.path} style={{ textDecoration: "none" }}>
          <Flex
            align="center"
            px="5"
            py="1.5"
            mx="6"
            role="group"
            cursor="pointer"
            borderLeft="2px solid #EBECED"
            sx={{ ...(isActiveNavItem && activeNavStyle) }}
            {...rest}
          >
            <Text
              sx={{
                fontFamily: "Aeonik",
                fontSize: ".875rem",
                lineHeight: "200%",
                noOfLines: 1,
                ...(isActiveNavItem && activeTitleStyle),
              }}
            >
              {item.title}
            </Text>
          </Flex>
        </Link>
      )}
    </>
  );
}
