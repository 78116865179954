import {
  Box,
  BoxProps,
  CloseButton,
  Divider,
  Flex,
  Image,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import React from "react";
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom";

import Logo from "../../assets/images/9jaPay Logo_Horizontal.svg";
import { CogToothIcon } from "../../components/icons";
import { usePermissions } from "../../hooks/usePermissions";
import navConfig from "./navConfig";
import NavItem from "./NavItem";
import SubnavItem from "./SubnavItem";

interface AppSidebarProps extends BoxProps {
  onClose: () => void;
}

export const DRAWER_WIDTH = 240;

export default function AppSidebar({ onClose, ...rest }: AppSidebarProps) {
  const { pathname } = useLocation();
  const permissions = usePermissions();
  const [isOpenIndex, setIsOpenIndex] = useState<string | null>(null);

  const match = (path?: string) => {
    return path ? !!matchPath({ path, end: true }, pathname) : false;
  };

  const handleOpen = (index: string) => {
    setIsOpenIndex(index === isOpenIndex ? null : index);
  };

  return (
    <Box
      as="aside"
      transition="3s ease"
      bg="#fafafa"
      w={{ base: "full", md: DRAWER_WIDTH }}
      pos="fixed"
      h="full"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          display: "none",
        },
        MsOverflowStyle: "none" /* IE and Edge */,
        scrollbarWidth: "none" /* Firefox */,
      }}
      {...rest}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        gap={24}
      >
        <Box>
          <Flex
            h="20"
            alignItems="center"
            mx="6"
            justifyContent="space-between"
            mb={8}
            id="flex"
            position="sticky"
            top="0"
            bg="#fafafa"
          >
            <Box>
              <RouterLink to="/app">
                <Image src={Logo} w={{ base: "60%", md: "100%" }} />
              </RouterLink>
              <Divider position="absolute" bottom="0" borderColor="#e0e3e6" />
            </Box>
            <CloseButton
              display={{ base: "flex", md: "none" }}
              onClick={onClose}
            />
          </Flex>

          {navConfig.map((navItem, index) => (
            <Box key={navItem.navGroup || index + 1}>
              {navItem.navGroup && (
                <Text
                  textTransform="uppercase"
                  fontSize=".6875rem"
                  lineHeight=".9375rem"
                  letterSpacing="-0.41px"
                  color="gray.300"
                  my={6}
                  mx={6}
                >
                  {navItem.navGroup}
                </Text>
              )}

              {navItem.navs.map(
                (nav) =>
                  (Boolean(
                    permissions?.[nav.key as keyof typeof permissions]
                  ) ||
                    nav.show) && (
                    <NavItem key={nav.title} item={nav} active={match} />
                  )
              )}

              {navItem.subnavGroup && (
                <>
                  {navItem.subnavGroup.map((subnavs, index) => (
                    <React.Fragment key={index}>
                      {(Boolean(
                        permissions?.[subnavs.key as keyof typeof permissions]
                      ) ||
                        subnavs.show) && (
                        <NavItem
                          key={subnavs.title}
                          item={{
                            title: subnavs.title,
                            icon: subnavs.icon,
                            endIcon: subnavs.endIcon,
                          }}
                          active={() =>
                            subnavs?.navs?.some(
                              (subItem) => pathname === subItem.path
                            )
                          }
                          onClick={() => handleOpen(subnavs?.key)}
                        />
                      )}

                      {isOpenIndex === subnavs?.key && (
                        <Box ml={5} mt="-3">
                          {subnavs?.navs.map((nav) => (
                            <SubnavItem
                              key={nav.title}
                              item={nav}
                              active={match}
                            />
                          ))}
                        </Box>
                      )}
                    </React.Fragment>
                  ))}
                </>
              )}
            </Box>
          ))}
        </Box>

        <Box>
          <Box mx={6}>
            <Divider mb={8} borderColor="#e0e3e6" />
          </Box>
          <NavItem
            item={{
              title: "Settings",
              icon: CogToothIcon,
              path: "/app/settings",
            }}
            active={match}
            mb={9}
          />
        </Box>
      </Box>
    </Box>
  );
}
