import { IconProps } from "@chakra-ui/react";

import {
  ChartIcon,
  ChartRoseBorderedIcon,
  ChevronDownIcon,
  CreditCardWithDots,
  DepositsIcon,
  DocumentAttachmentIcon,
  DocumentPinnedIcon,
  QRIcon,
  SquaresPlusIcon,
  WithdrawalsIcon,
} from "../../components/icons";

type NavConfigType = {
  navGroup: null | string;
  subnavGroup?: {
    title: string;
    key: string;
    icon: (props: IconProps) => JSX.Element;
    endIcon: (props: IconProps) => JSX.Element;
    show?: boolean;
    navs: {
      title: string;
      path: string;
    }[];
  }[];
  navs: {
    title: string;
    key?: string;
    path: string;
    icon: (props: IconProps) => JSX.Element;
    show?: boolean;
  }[];
}[];

const navConfig: NavConfigType = [
  {
    navGroup: null,
    navs: [
      {
        title: "Dashboard",
        key: "deposits",
        path: "/app/dashboard",
        icon: SquaresPlusIcon,
      },
    ],
  },
  {
    navGroup: "Financial Activities",
    subnavGroup: [
      {
        title: "Deposits",
        key: "deposits",
        icon: DepositsIcon,
        endIcon: ChevronDownIcon,
        navs: [
          {
            title: "Overview",
            path: "/app/deposits",
          },
          {
            title: "History",
            path: "/app/deposits-history",
          },
        ],
      },
      {
        title: "Withdrawals",
        key: "withdrawals",
        icon: WithdrawalsIcon,
        endIcon: ChevronDownIcon,
        navs: [
          {
            title: "Overview",
            path: "/app/withdrawals",
          },
          {
            title: "History",
            path: "/app/withdrawals-history",
          },
        ],
      },
      {
        title: "QR Payments",
        key: "qr",
        icon: QRIcon,
        endIcon: ChevronDownIcon,
        navs: [
          {
            title: "Overview",
            path: "/app/qr-payments",
          },
          {
            title: "History",
            path: "/app/qr-payments-history",
          },
        ],
      },
      {
        title: "Virtual Accounts",
        icon: CreditCardWithDots,
        endIcon: ChevronDownIcon,
        key: "virtual-account",
        show: true,
        navs: [
          {
            title: "Virtual Accounts",
            path: "/app/virtual-accounts",
          },
          {
            title: "Transactions",
            path: "/app/transactions",
          },
          {
            title: "Daily Sweep",
            path: "/app/daily-sweep",
          },
        ],
      },
    ],
    navs: [],
  },
  {
    navGroup: "Reports",
    subnavGroup: [
      {
        title: "Activity Reports",
        key: "financial-reports",
        icon: DocumentPinnedIcon,
        endIcon: ChevronDownIcon,
        show: true,
        navs: [
          {
            title: "Deposits",
            path: "/app/deposits-reports",
          },
          {
            title: "Withdrawals",
            path: "/app/withdrawal-reports",
          },
          {
            title: "QR Payments",
            path: "/app/qr-payments-reports",
          },
          {
            title: "Virtual Accounts",
            path: "/app/virtual-accounts-reports",
          },
        ],
      },
    ],
    navs: [
      {
        title: "Reports",
        key: "reports",
        path: "/app/reports",
        icon: ChartIcon,
        show: true,
      },
      {
        title: "Funding Reports",
        key: "funding-reports",
        path: "/app/funding-reports",
        icon: ChartRoseBorderedIcon,
        show: true,
      },
      {
        title: "Account Statements",
        key: "account-statements",
        path: "/app/account-statements",
        icon: DocumentAttachmentIcon,
        show: true,
      },
    ],
  },
];

export default navConfig;

/* TODO: chnage keys for reports group*/
