import { Box } from "@chakra-ui/react";

import DepositData from "./DepositData";

export default function DepositTransactionsCard() {
  return (
    <Box
      background="#fff"
      border="1px solid #e0e3e6"
      borderRadius="24px"
      padding={6}
      minH="272px"
      height="100%"
    >
      <DepositData />
    </Box>
  );
}
