import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IApiState } from "../../types/api";
import { RootState } from "..";
import { IRole } from "./roleSlice";

interface LoginCredentials {
  email: string;
  password: string;
}

interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  roleId: string;
  lastLoginAt: string;
  isActive: boolean;
  createdAt: string;
  updatedAt: string;
  role: IRole;
}

interface IAuthState extends IApiState {
  user: IUser | null;
}

const storedUser = localStorage.getItem("user");

const initialUser = storedUser ? JSON.parse(storedUser) : null;

const initialState: IAuthState = {
  status: "idle",
  error: "",
  user: initialUser,
};

export const loginUserAsync = createAsyncThunk<
  any,
  LoginCredentials,
  { rejectValue: string }
>("auth/loginUser", async (credentials, { rejectWithValue }) => {
  try {
    const { data } = await api.post("/auth/login", credentials);

    const expiryTime = new Date(
      Date.now().valueOf() + data?.data?.token?.expiresIn - 120000
    ).toString();

    localStorage.setItem("user", JSON.stringify(data?.data?.user));
    localStorage.setItem("token", data?.data?.token?.accessToken);
    localStorage.setItem("refresh-token", data?.data?.token?.refreshToken);

    localStorage.setItem("expirationTime", expiryTime);

    return data;
  } catch (err) {
    const error = err as AxiosError;

    return rejectWithValue("Your login credential is incorrect");
  }
});

export const fetchUserAsync = createAsyncThunk<{ data: IUser }, { id: string }>(
  "auth/fetchUser",
  async ({ id }, { rejectWithValue }) => {
    try {
      const { data } = await api.get<{ data: IUser }>(`/users/${id}`);
      return data;
    } catch (err) {
      const error = err as AxiosError;

      localStorage.removeItem("user");

      return rejectWithValue(error.response?.data);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuthState: (state) => {
      state.status = "idle";
      state.error = "";
      state.user = null;
    },
    logoutUser: (state) => {
      localStorage.clear();
      sessionStorage.clear();
      return { ...state, user: null, id: null };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginUserAsync.pending, (state, action) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action?.payload?.data?.user;
      })
      .addCase(fetchUserAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.user = action.payload.data;
      })
      .addCase(loginUserAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetAuthState, logoutUser } = authSlice.actions;

export const selectAuthState = (state: RootState) => state.auth;

export default authSlice.reducer;
