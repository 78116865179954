import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";

export interface Permission {
  id: string;
  name: string;
  description: string;
  slug: string;
  action: string;
  module: {
    id: string;
    name: string;
    slug: string;
  };
  moduleId: string;
  isDefault: boolean;
}

export interface PermissionsState {
  permissions: Permission[];
  isLoading: boolean;
  isError: boolean;
  error: string;
}

const initialState: PermissionsState = {
  permissions: [],
  isLoading: false,
  isError: false,
  error: "",
};

export const fetchPermissionsAsync = createAsyncThunk(
  "permissions/fetchPermissions",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/permissions");
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const permissionsSlice = createSlice({
  name: "permissions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermissionsAsync.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
        state.error = "";
      })
      .addCase(fetchPermissionsAsync.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.error = "";
        state.permissions = action.payload;
      })
      .addCase(fetchPermissionsAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload as string;
      });
  },
});

export default permissionsSlice.reducer;
