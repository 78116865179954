import { Flex, FlexProps, Icon, IconProps, Link, Text } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface NavItemProps extends FlexProps {
  item: {
    title: string;
    path?: string;
    icon: (props: IconProps) => JSX.Element;
    endIcon?: (props: IconProps) => JSX.Element;
  };
  active: (path?: string) => boolean;
}

export default function NavItem({ item, active, ...rest }: NavItemProps) {
  const isActiveNavItem = active(item.path);

  const activeNavStyle: React.CSSProperties = {
    background: "#1a1c1f",
  };

  const activeTitleStyle: React.CSSProperties = {
    color: "white",
    fontWeight: "700",
  };

  const hoverStyle: React.CSSProperties = {
    background: "rgba(26, 28, 31, 0.95)",
  };

  return (
    <>
      {item.path && (
        <Link as={RouterLink} to={item.path} style={{ textDecoration: "none" }}>
          <Flex
            align="center"
            px="2"
            py="1.5"
            mx="6"
            my="3"
            role="group"
            cursor="pointer"
            borderRadius="8px"
            sx={{ ...(isActiveNavItem && activeNavStyle) }}
            _hover={{
              ...(!isActiveNavItem && hoverStyle),
              ...(!isActiveNavItem && {
                "& svg": {
                  fill: "white",
                  stroke: "white",
                },
              }),
              ...activeTitleStyle,
            }}
            {...rest}
          >
            <Icon
              as={item.icon}
              mr="4"
              ml="1"
              fontSize="20"
              color={isActiveNavItem ? "green.500" : "white"}
              stroke={isActiveNavItem ? "green.500" : "#1A1C1F"}
              fill={isActiveNavItem ? "green.500" : "currentColor"}
            />
            <Text
              sx={{
                fontFamily: "Aeonik",
                fontSize: ".875rem",
                lineHeight: "200%",
                noOfLines: 1,
                ...(isActiveNavItem && activeTitleStyle),
              }}
            >
              {item.title}
            </Text>
          </Flex>
        </Link>
      )}
      {!item.path && (
        <Flex
          align="center"
          px="0.5"
          py="1.5"
          mx="6"
          my="3"
          role="group"
          cursor="pointer"
          borderRadius="8px"
          sx={{ ...(isActiveNavItem && activeNavStyle) }}
          _hover={{
            ...(!isActiveNavItem && hoverStyle),
            ...(!isActiveNavItem && {
              "& svg": {
                fill: "white",
                stroke: "white",
              },
            }),
            ...activeTitleStyle,
          }}
          {...rest}
        >
          <Icon
            as={item.icon}
            mr="4"
            ml="3"
            fontSize="20"
            color={isActiveNavItem ? "green.500" : "white"}
            stroke={isActiveNavItem ? "green.500" : "#1A1C1F"}
            fill={isActiveNavItem ? "green.500" : "currentColor"}
          />
          <Text
            sx={{
              fontFamily: "Aeonik",
              fontSize: ".875rem",
              lineHeight: "200%",
              width: "12.5rem",
              noOfLines: 1,
              ...(isActiveNavItem && activeTitleStyle),
            }}
          >
            {item.title}
          </Text>
          {item.endIcon && (
            <Icon
              as={item.endIcon}
              mr="2"
              ml="2"
              fontSize="20"
              color={isActiveNavItem ? "green.500" : "white"}
              stroke={isActiveNavItem ? "green.500" : "#1A1C1F"}
              fill={isActiveNavItem ? "green.500" : "currentColor"}
            />
          )}
        </Flex>
      )}
    </>
  );
}
