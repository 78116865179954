import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import React from "react";

import {
  formatDate,
  formatKoboToNairaAndKobo,
} from "../../../../helpers/utilityFunctions";
import useGetUIErrorMessage from "../../../../hooks/useGetUIErrorMessage";
import { ITransactionData } from "../../../../modules/VirtualAccounts/Transactions/TransactionTable";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  refundTransactionAsync,
  refundTransactionReset,
} from "../../../../store/slices/refundTransactionSlice";
import { PrimaryButton } from "../../CustomButtons";
import { CustomModalFooter } from "../../CustomModal";
import { AnimatedLogo, StatusBox } from "../../Misc";

interface IRefundModal {
  id?: string;
  close?: () => void;
  transactionDetails?: ITransactionData;
}
export default function RefundTransactionModal({
  id,
  close,
  transactionDetails,
}: IRefundModal) {
  const dispatch = useAppDispatch();

  const { status, data } = useAppSelector((state) => state.refundTransaction);

  const handleRefundTransaction = (transactionId: string) => {
    dispatch(refundTransactionAsync(transactionId ?? ""));
  };

  const errorMessage = useGetUIErrorMessage({
    message: data?.message,
    errors: data?.errors,
  });

  if (status === "loading") {
    return (
      <Box minH="330px">
        <AbsoluteCenter>
          <AnimatedLogo />
        </AbsoluteCenter>
      </Box>
    );
  }

  if (status === "success") {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="success" title="Notification Sent.">
              Refund Initiated! Your refund is being processed. Keep an eye on
              your dashboard for updates on successful completion.
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            as={ModalCloseButton}
            w="full"
            top="initial"
            right="initial"
          >
            Dismiss
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  if (status === "failed") {
    return (
      <>
        <Box minH="330px" mb="82px">
          <AbsoluteCenter axis="vertical" top="45%" left="0" right="0">
            <StatusBox variant="error" title="Failed to send Nudge.">
              {errorMessage}
            </StatusBox>
          </AbsoluteCenter>
        </Box>
        <CustomModalFooter>
          <PrimaryButton
            onClick={() => dispatch(refundTransactionReset())}
            w="full"
          >
            Retry
          </PrimaryButton>
        </CustomModalFooter>
      </>
    );
  }

  return (
    <>
      <Box mb="-20">
        <Text fontSize="1.5rem" fontWeight={500} textAlign="center">
          Transaction Refund
        </Text>
        <Text
          fontSize="1.125rem"
          color="gray.200"
          textAlign="center"
          marginTop="12px"
          marginBottom="36px"
        >
          Are you sure you want to refund this failed transaction?
        </Text>
        <Box
          sx={{
            backgroundColor: "ash.200",
            padding: "10px",
            marginBottom: "36px",
            borderRadius: "12px",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Text sx={{ width: "40%" }}>Transaction Date: </Text>{" "}
            <Text>
              {formatDate(transactionDetails?.transactionDate ?? new Date())}{" "}
            </Text>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Text sx={{ width: "40%" }}>Account Number: </Text>{" "}
            <Text> {transactionDetails?.accountNumber}</Text>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Text sx={{ width: "40%" }}>Amount :</Text>
            <Text>
              ₦ {formatKoboToNairaAndKobo(transactionDetails?.amount ?? 0)}
            </Text>
          </Box>
        </Box>
        <Flex mx="-7" gap={4} justifyContent="space-between">
          <Button
            sx={{
              bg: "red",
              color: "#ffffff",
              borderRadius: "12px",
              width: "214px",
              height: "48px",
              fontWeight: 0,
              fontSize: "1.125rem",
              "&:hover": {
                bg: "red",
              },
            }}
            onClick={() => {
              handleRefundTransaction(id ?? "");
            }}
          >
            Yes, Refund
          </Button>
          <Button
            sx={{
              bg: "#EBEDEF",
              borderRadius: "12px",
              width: "214px",
              height: "48px",
              fontWeight: 0,
              fontSize: "1.125rem",
              "&:hover": {
                bg: "#EBEDEF",
              },
            }}
            onClick={close}
          >
            {" "}
            Not now
          </Button>
        </Flex>
      </Box>
    </>
  );
}
