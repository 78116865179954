import { Box, BoxProps, Image, Text } from "@chakra-ui/react";

import ErrorImage from "../../../assets/images/error-image.svg";
import MailSentImg from "../../../assets/images/mail-sent.svg";
import SuccessImage from "../../../assets/images/success-image.svg";

interface SuccessBoxProps extends BoxProps {
  title: string;
  variant: "success" | "error" | "mailSent";
  children: string;
  /**
   * @default 200px
   */
  imgSize?: number | string;
}

export default function SuccessBox({
  title,
  variant,
  children,
  imgSize = "200px",
  ...rest
}: SuccessBoxProps) {
  const isSuccessVariant = variant === "success";
  return (
    <Box p="10" {...rest}>
      <Image
        src={
          isSuccessVariant
            ? SuccessImage
            : variant === "mailSent"
            ? MailSentImg
            : ErrorImage
        }
        alt={variant}
        boxSize={imgSize}
        mx="auto"
        mb="4"
      />
      <Text
        fontSize="24px"
        lineHeight="133%"
        textAlign="center"
        fontWeight={500}
        mb={3}
      >
        {title}
      </Text>
      <Text letterSpacing="-0.12px" color="gray.200" textAlign="center">
        {children}
      </Text>
    </Box>
  );
}
