import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { clearStorage } from "../../helpers/storage";
import { IApiResponseError } from "../../types/api";
import { resetStore } from "../resetStore";
import { showAlert } from "./appToastSlice";
import { resetAuthState } from "./authSlice";

interface ISignOutResponse {
  status?: boolean;
  message?: string;
  errors?: IApiResponseError[];
}

export const signOut = createAsyncThunk(
  "auth/SignOut",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      await api.delete("/auth/logout");

      clearStorage();
      dispatch(resetStore());
      dispatch(resetAuthState());
    } catch (err) {
      const error = err as AxiosError<ISignOutResponse>;
      dispatch(
        showAlert({
          status: "error",
          description:
            error?.response?.data?.message ??
            "An error occurred while signing you out, try again",
        })
      );
      return rejectWithValue(error.response?.data);
    }
  }
);
