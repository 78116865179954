/**
 * Formats milliseconds to time in mm:ss format
 * @param {number} timeInMs The number of milliseconds
 * @returns {string} formatted time in mm:ss
 */
export const formatMilliSecondsToMmSs = (timeInMs: number): string => {
  const minutes = parseInt(String((timeInMs / (1000 * 60)) % 60));
  const seconds = parseInt(String((timeInMs / 1000) % 60));

  // format time to mm:ss
  const mmMinutes = Number(minutes) < 10 ? "0" + minutes : minutes;
  const ssSeconds = Number(seconds) < 10 ? "0" + seconds : seconds;

  return `${mmMinutes}:${ssSeconds}`;
};

/**
 * Formats a string to a title-cased string
 * @param {string} value - The string to be formatted.
 * @returns {string} The Title Cased String.
 */
export const getTitleCase = (value: string) => {
  return value.replace(
    /\b\w+/g,
    (match) => match.charAt(0).toUpperCase() + match.slice(1).toLowerCase()
  );
};

export const getSentenceCase = (value: string) => {
  return value.replace(/_/g, " ");
};

/**
 * Extracts the last part of a path and converts it to title case.
 * Hyphens in the last part are replaced with spaces.
 * @param {string} path - The path string to extract the last part from.
 * @returns {string} The last part of the path in title case.
 */
export const getLastPathTitle = (path: string): string => {
  // Remove leading and trailing slashes
  path = path.replace(/^(\/|\/$)/g, "");

  // Split the path into parts using slashes
  const parts = path.split("/");

  // Get the last part of the path
  const lastPart = parts[parts.length - 1];

  // Convert hyphens to spaces
  const lastPartHyphensToSpaces = lastPart.replace(/-/g, " ");

  return getTitleCase(lastPartHyphensToSpaces);
};

/**
 * Determines the appropriate article ("a" or "an") based on the given word.
 * @param {string} word - The word to determine the article for.
 * @returns {string} The article ("a" or "an").
 */
export const getArticle = (word: string): string => {
  const startsWithVowel = /^[aeiou]/i.test(word);
  return startsWithVowel ? "an" : "a";
};

/**
 * Formats a number to various representations.
 * @param num - The number to format.
 * @returns An object containing the formatted representations of the number.
 */
export const formatNumber = (num: number | string | undefined) => {
  // Convert the input to a valid number
  const parsedNum = parseFloat(num as string);

  if (isNaN(parsedNum)) {
    // Return 0 for invalid inputs
    return {
      nairaString: "₦0",
      nairaStringWithKobo: "₦0.00",
      koboValue: "00",
      formattedString: "0",
      formattedStringWithKobo: "0.00",
      formattedStringWithLeadingZero: "0",
      nairaValue: "0",
      truncatedFormattedString: "0",
    };
  }

  // Format the number to nairaString (1000 -> ₦1,000)
  const nairaString = `₦${parsedNum.toLocaleString()}`;

  // Format the number to nairaStringWithKobo (1000 -> ₦1,000.00)
  const nairaStringWithKobo = parsedNum.toLocaleString("en-NG", {
    style: "currency",
    currency: "NGN",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Format the number to formattedString (1000 -> 1,000)
  const formattedString = parsedNum.toLocaleString();

  const nairaValue = Math.floor(parsedNum).toLocaleString();

  // Format the number to formattedStringWithKobo (1000 -> 1,000.00)
  const formattedStringWithKobo = parsedNum.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Get the kobo value (1000 -> 00 or 100.32 -> 32)
  const numParts = parsedNum.toFixed(2).split(".");
  const koboValue = numParts[1];

  const formattedStringWithLeadingZero =
    parsedNum < 10 ? `0${parsedNum}` : parsedNum.toString();

  // Format the number to a whole number (truncated) formattedString
  const truncatedFormattedString = Math.trunc(parsedNum).toLocaleString();

  return {
    nairaString,
    nairaStringWithKobo,
    nairaValue,
    koboValue,
    formattedString,
    formattedStringWithKobo,
    formattedStringWithLeadingZero,
    truncatedFormattedString,
  };
};

/**
 * Formats a date into the "DD MMM, YYYY" format.
 * @param {Date | string | number} date - The date to format.
 * @returns {string} The formatted date string, or an empty string if the input is not a valid date.
 */
export const formatDate = (date: Date | string | number): string => {
  const parsedDate = new Date(date);

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return ""; // Return empty string for invalid or empty input
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return parsedDate.toLocaleDateString("en-US", options);
};

/**
 * Formats a date into the "DD MMM, YYYY" format.
 * @param {Date | string | number} date - The date to format.
 * @returns {string} The formatted date string, or an empty string if the input is not a valid date.
 */
export const formatDateAndTime = (date?: Date | string | number): string => {
  if (!date) return "";

  const parsedDate = new Date(date);

  if (!(parsedDate instanceof Date) || isNaN(parsedDate.getTime())) {
    return ""; // Return empty string for invalid or empty input
  }

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return `${parsedDate.toLocaleDateString("en-US", options)} - ${parsedDate
    .toLocaleTimeString()
    .substring(0, 5)}`;
};

/**
 * Formats the date in a format the backend accepts e.g. "2023-10-23"
 * @param {Date | string | number} params - The date to be formatted.
 * @returns {string} The formatted date string.
 */
export const getApiFormattedDate = (params: string | Date) => {
  const date = new Date(params);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month}-${day}`;
};

/**
 * Parses a JSON string into an object. If the string is not a valid JSON, an empty object is returned.
 * @param {string} value - The JSON string to parse.
 * @returns {object} The parsed object, or an empty object if the input is not a valid JSON.
 */
export const jsonParser = (value: string): Record<string, unknown> => {
  try {
    return JSON.parse(value) as Record<string, unknown>;
  } catch (e) {
    return {};
  }
};

/**
 * Converts the amount from kobo to naira and kobo format.
 * @param {number} kobo - The amount in kobo.
 * @returns {string} The converted amount in naira and kobo format.
 */
export const formatKoboToNairaAndKobo = (kobo: number): string => {
  const naira = kobo / 100;
  return naira.toLocaleString("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

/**
 * Parses a date string in the format "day/month/year" and returns a Date object.
 *
 * @param {string} value - The date string in the format "day/month/year".
 * @returns {Date} A Date object representing the parsed date.
 *
 * @example
 * const formattedDate = formatDateWithSlashes("28/11/2023");
 * console.log(formattedDate); // Wed Nov 28 2023 00:00:00 GMT+0000 (Coordinated Universal Time)
 */
export const formatDateWithSlashes = (value: string): Date => {
  const [day, month, year] = value.split("/").map(Number);

  // Months in JavaScript Dates are 0-indexed, so we subtract 1 from the month
  const newDate = new Date(year, month - 1, day);

  return newDate;
};

/**
 * Calculates the number of days ago from a given date.
 *
 * @param {Date} date - The date to calculate the days ago from.
 * @returns {string} The formatted number of days ago.
 */
export const getDaysAgo = (date: Date): string => {
  const now = new Date();
  const timeDifference = now.getTime() - date.getTime();
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysAgo === 0) return "Today";

  if (daysAgo === 1) return "Yesterday";

  return `${daysAgo} days ago`;
};

//accepts a sentence and the number you wants to split the sentence by and makes it shorter
export const formatStringToSeeMore = (
  sentence: string,
  num: number
): string => {
  const words = sentence.split(" ", num);
  const formattedSentence = words.map((word, index) => {
    if (index === words.length - 1) {
      return word;
    }
    return `${word} `;
  });
  return formattedSentence.join(" ");
};

//function to convert camelCase to display separated words
export const formatCamelCaseToWords = (camelCase: string): string => {
  return camelCase
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .trim();
};
