import React, { useEffect } from "react";

import { useAppDispatch } from "../../store/hooks";
import { refreshTokenAsync } from "../../store/slices/refreshTokenSlice";

export const SessionTimer = () => {
  const getExpirationTime = localStorage.getItem("expirationTime") ?? "";

  const expiresIn = new Date(getExpirationTime).getTime();
  const dispatch = useAppDispatch();

  // Calculate the refresh token time
  const refreshTime = expiresIn - Date.now();

  useEffect(() => {
    const refreshTokenTimer = setTimeout(() => {
      dispatch(refreshTokenAsync());
    }, refreshTime);

    return () => {
      return clearTimeout(refreshTokenTimer);
    };
  }, [dispatch, refreshTime]);

  return <></>;
};
