import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { StatusType } from "../../types/api";
import { IFundingReport } from "../../types/reports";

interface IFundingReportApiState<T> {
  data: T | null;
  status: StatusType;
  error?: string;
  message?: string;
  limit?: number;
}

const initialState: IFundingReportApiState<Partial<IFundingReport>> = {
  data: null,
  status: "idle",
  error: "",
};

export const getFundingReportsDataAsync = createAsyncThunk(
  "reports",
  async (_, { rejectWithValue }) => {
    const url = "/reports/funding";

    try {
      const { data } = await api.post(url, {});
      return data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

export const getFundingReportsDataSlice = createSlice({
  name: "reports/getFundingReportsData",
  initialState,
  reducers: {
    resetGetFundingReportsData: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFundingReportsDataAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getFundingReportsDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data;
        state.limit = action.payload.data.limit;
      })
      .addCase(getFundingReportsDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetGetFundingReportsData } =
  getFundingReportsDataSlice.actions;

export default getFundingReportsDataSlice.reducer;
