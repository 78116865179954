import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { IRole } from "./roleSlice";

type StatusType = "idle" | "loading" | "failed";

export interface IUserUpdate {
  password: string;
  firstName: string;
  lastName: string;
  email: string;
}

export interface IUserRole {
  id: string;
  name: string;
  description: string;
  slug: string;
  isDefault: boolean;
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
  role: IRole;
}

export interface IUserState {
  users: IUser[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
  status: StatusType;
}

const initialState: IUserState = {
  users: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",
  status: "idle",
};

export const fetchUsersAsync = createAsyncThunk<IUser[], undefined>(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<{ data: IUser[] }>("/users");
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const createUserAsync = createAsyncThunk(
  "roles/createUser",
  async (newUser: IUserUpdate, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post("/users", newUser);
      dispatch(fetchUsersAsync());
      return response.data.data[0];
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "users/updateUser",
  async (
    { id, updatedUser }: { id: string; updatedUser: IUserUpdate },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.put(`/users/${id}`, updatedUser);
      dispatch(fetchUsersAsync());
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersAsync.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.users = action.payload;
        }
      })
      .addMatcher(
        isAnyOf(
          fetchUsersAsync.fulfilled,
          updateUserAsync.fulfilled,
          createUserAsync.fulfilled
        ),
        (state) => {
          state.isLoading = false;
          state.isError = false;
          state.error = "";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchUsersAsync.pending,
          updateUserAsync.pending,
          createUserAsync.pending
        ),
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = "";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchUsersAsync.rejected,
          updateUserAsync.rejected,
          createUserAsync.rejected
        ),
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.payload as string;
        }
      );
  },
});

export default usersSlice.reducer;
