import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { Permission } from "./permissionsSlice";

export interface IRoleEdit {
  name: string;
  description: string;
  permissions: string[];
}

export interface IRole {
  id: string;
  name: string;
  description: string;
  slug: string;
  isDefault: boolean;
  permissions: Permission[];
}

export interface IRoleState {
  roles: IRole[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
}

const initialState: IRoleState = {
  roles: [],
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",
};

export const fetchRolesAsync = createAsyncThunk<{ data: IRole[] }, undefined>(
  "roles/fetchRoles",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<{ data: IRole[] }>("/roles");

      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const addNewRoleAsync = createAsyncThunk(
  "roles/addNewRole",
  async (newRole: IRoleEdit, { rejectWithValue, dispatch }) => {
    try {
      const response = await api.post("/roles", newRole);
      dispatch(fetchRolesAsync());
      return response.data.data[0];
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const updateRoleAsync = createAsyncThunk(
  "roles/updateRole",
  async (
    { id, updatedRole }: { id: string; updatedRole: IRoleEdit },
    { rejectWithValue, dispatch }
  ) => {
    try {
      const response = await api.put(`/roles/${id}`, updatedRole);
      dispatch(fetchRolesAsync());
      return response.data.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRolesAsync.fulfilled, (state, action) => {
        if (Array.isArray(action.payload)) {
          state.roles = action.payload;
        }
      })
      .addMatcher(
        isAnyOf(
          fetchRolesAsync.fulfilled,
          updateRoleAsync.fulfilled,
          addNewRoleAsync.fulfilled
        ),
        (state) => {
          state.isLoading = false;
          state.isError = false;
          state.error = "";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchRolesAsync.pending,
          updateRoleAsync.pending,
          addNewRoleAsync.pending
        ),
        (state) => {
          state.isLoading = true;
          state.isError = false;
          state.error = "";
        }
      )
      .addMatcher(
        isAnyOf(
          fetchRolesAsync.rejected,
          updateRoleAsync.rejected,
          addNewRoleAsync.rejected
        ),
        (state, action) => {
          state.isLoading = false;
          state.isError = true;
          state.error = action.payload as string;
        }
      );
  },
});

export default rolesSlice.reducer;
