import { IApiResponseError } from "../types/api";
import useServerErrorAdapter from "./useServerErrorAdapter";

interface UseGetUIErrorMessageParams {
  /** The message from backend */
  message?: string;
  /** The errors array which uses IApiResponseError Interface */
  errors?: IApiResponseError[];
  /** A fallback custom message if generic error message is not wanted */
  customMessage?: string;
}

export default function useGetUIErrorMessage({
  message,
  errors,
  customMessage,
}: UseGetUIErrorMessageParams) {
  const genericMessage =
    "An error occurred, this might be a temporary error. Please try again";

  const serverErrorAdapter = useServerErrorAdapter();

  if (!message) return customMessage ?? genericMessage;

  if (errors?.length && errors.length > 0) {
    return serverErrorAdapter(errors).join("\n") || genericMessage;
  }

  // If errors is empty but message is 'your data is invalid', return custom or generic error message
  if (message.toLowerCase() === "your data is invalid") {
    return customMessage ?? genericMessage;
  }

  return message;
}
