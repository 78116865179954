import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation, useParams } from "react-router-dom";

import { usePermissions } from "../hooks/usePermissions";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { fetchUserAsync, selectAuthState } from "../store/slices/authSlice";
import { fetchRoleAsync } from "../store/slices/editRoleSlice";

interface PublicRouteProps {
  children: React.ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const dispatch = useAppDispatch();
  const authState = useAppSelector(selectAuthState);
  const permissions = usePermissions();

  if (authState.user && permissions?.["deposits" as keyof typeof permissions]) {
    return <Navigate to="/app" />;
  }

  return <>{children}</>;
};

export default PublicRoute;
