import PrimaryButton, {
  PrimaryButtonProps,
} from "../CustomButtons/PrimaryButton";
import CustomModalFooter from "./CustomModalFooter";

interface CustomModalActionPrimaryButtonProps extends PrimaryButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children: string;
}
export default function CustomModalActionPrimaryButton({
  onClick,
  children,
  ...rest
}: CustomModalActionPrimaryButtonProps) {
  return (
    <CustomModalFooter>
      <PrimaryButton onClick={onClick} w="full" {...rest}>
        {children}
      </PrimaryButton>
    </CustomModalFooter>
  );
}
