// store/slices/depositIndicatorSlice.ts
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { getApiFormattedDate } from "../../helpers/utilityFunctions";

interface WithdrawalIndicatorSliceProps {
  page: number;
  limit?: number;
  startDate?: string;
  endDate?: string;
}

interface IndicatorData {
  total: number;
  successful: number;
  pending: number;
  failed: number;
}

interface WithdrawalIndicatorState {
  data: IndicatorData;
  status: string;
  error: string;
}

const initialState: WithdrawalIndicatorState = {
  data: {
    total: 0,
    successful: 0,
    pending: 0,
    failed: 0,
  },
  status: "idle",
  error: "",
};

export const getWithdrawalIndicatorTransactionsAsync = createAsyncThunk(
  "indicator/getWithdrawalTransactionsData",
  async ({ startDate }: WithdrawalIndicatorSliceProps, { rejectWithValue }) => {
    try {
      const response = await api.get(
        `/withdrawals/transactions/metrics?startDate=${startDate}&endDate=${getApiFormattedDate(
          new Date()
        )}`
      );
      return {
        total: response.data.data.totalTransactions,
        pending: response.data.data.totalPending,
        failed: response.data.data.totalFailed,
        successful: response.data.data.totalSuccessful,
      };
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

const withdrawalIndicatorSlice = createSlice({
  name: "withdrawalIndicator",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWithdrawalIndicatorTransactionsAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(
        getWithdrawalIndicatorTransactionsAsync.fulfilled,
        (state, action) => {
          state.status = "idle";
          state.data = action.payload;
        }
      )
      .addCase(
        getWithdrawalIndicatorTransactionsAsync.rejected,
        (state, action) => {
          state.status = "failed";
          state.error = action.payload as string;
        }
      );
  },
});

export default withdrawalIndicatorSlice.reducer;
