import { Button, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { formatMilliSecondsToMmSs } from "../../helpers/utilityFunctions";

interface ResendTimerProps {
  /**
   * countdown timer in milliseconds
   * @default 120000 (2minutes)
   */
  countdownTime?: number;
}

export default function ResendTimer({
  countdownTime = 120000,
}: ResendTimerProps) {
  const [timer, setTimer] = useState(countdownTime);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (timer <= 0) {
        clearInterval(intervalId);
        return;
      }
      setTimer((prevTimer) => prevTimer - 1000);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timer]);

  return (
    <>
      {timer !== 0 ? (
        <Text fontFamily="Aeonik" letterSpacing="-0.12px">
          {formatMilliSecondsToMmSs(timer)}
        </Text>
      ) : null}

      <Button
        colorScheme="green"
        variant="link"
        isDisabled={timer > 0}
        onClick={() => {
          setTimer(countdownTime);
        }}
        fontWeight={400}
      >
        Resend
      </Button>
    </>
  );
}
