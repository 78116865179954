import { Box, Skeleton } from "@chakra-ui/react";
import { SignJWT } from "jose";
import { useEffect, useState } from "react";

export default function VirtualAccountsDashboard() {
  const [iframeUrl, setIframeUrl] = useState("");
  const [loading, setLoading] = useState(true);

  const siteUrl = process.env.REACT_APP_METABASE_SITE_URL;
  const METABASE_SECRET_KEY = process.env
    .REACT_APP_METABASE_SECRET_KEY as string;

  useEffect(() => {
    const payload = {
      resource: { dashboard: 71 },
      params: {},
      exp: Math.round(Date.now() / 1000) + 10 * 60, // 10 minute expiration
    };

    //encoding the secret key
    const eSecretKey = new TextEncoder().encode(METABASE_SECRET_KEY);

    const jwt = new SignJWT(payload)
      .setProtectedHeader({ alg: "HS256" })
      .setIssuedAt();

    jwt
      .sign(eSecretKey)
      .then((token) => {
        const url = `${siteUrl}/embed/dashboard/${token}#bordered=false&titled=false`;

        setIframeUrl(() => url);
      })
      .catch((error: unknown) => {
        //Do nothing
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [METABASE_SECRET_KEY]);

  return (
    <>
      <Box
        background="#fff"
        border="1px solid #e0e3e6"
        borderRadius="24px"
        padding={6}
        minH="100px"
        height="100%"
      >
        {loading ? (
          <Skeleton
            height="100vh"
            width="100%"
            marginTop={4}
            startColor="gray.100"
            endColor="gray.200"
          />
        ) : null}
        <iframe
          src={iframeUrl}
          onLoad={() => setLoading(false)}
          title="virtual accounts"
          style={{ width: "100%", minHeight: "100vh", border: "none" }}
        />
      </Box>
    </>
  );
}
