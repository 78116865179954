import { TableRowProps, Tr } from "@chakra-ui/react";

interface CustomTrProps extends TableRowProps {
  children: React.ReactNode;
}

export default function CustomTr({ children, ...props }: CustomTrProps) {
  return (
    <Tr
      _hover={{ background: "#F0FFF0", transition: "all 0.2s" }}
      borderBottom="1px solid #EBEDEF"
      {...props}
    >
      {children}
    </Tr>
  );
}
