import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { StatusType } from "../../types/api";
import { IReport } from "../../types/reports";

interface GetHourlyReportsValues {
  page: number;
  limit?: number;
}

interface IHourlyReportApiState<T> {
  data: T | null;
  status: StatusType;
  error?: string;
  message?: string;
  limit?: number;
}

const initialState: IHourlyReportApiState<IReport[]> = {
  data: null,
  status: "idle",
  error: "",
};

export const getHourlyReportsDataAsync = createAsyncThunk(
  "reports/hourly",
  async ({ page, limit }: GetHourlyReportsValues, { rejectWithValue }) => {
    const url = "/reports";

    try {
      const { data } = await api.post(url, { page, limit, requestType: 1 });
      return data;
    } catch (err: any) {
      if (err.response && err.response.data) {
        return rejectWithValue(err.response.data);
      }
      throw err;
    }
  }
);

export const getHourlyReportsDataSlice = createSlice({
  name: "reports/getHourlyReportsData",
  initialState,
  reducers: {
    resetGetHourlyReportsData: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHourlyReportsDataAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(getHourlyReportsDataAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.data;
        state.limit = action.payload.data.limit;
      })
      .addCase(getHourlyReportsDataAsync.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const { resetGetHourlyReportsData } = getHourlyReportsDataSlice.actions;

export default getHourlyReportsDataSlice.reducer;
