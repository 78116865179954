import { Navigate } from "react-router-dom";

import InactivityDetector from "../components/utils/InactivityDetector";
import { SessionTimer } from "../components/utils/SessionTimer";
import { useAppSelector } from "../store/hooks";
import { selectAuthState } from "../store/slices/authSlice";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const authState = useAppSelector(selectAuthState);

  if (authState.user) {
    return (
      <InactivityDetector>
        <SessionTimer />
        {children}
      </InactivityDetector>
    );
  }

  return <Navigate to="/" replace />;
};

export default PrivateRoute;
