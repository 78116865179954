import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerProps,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { useRef } from "react";

import { ArrowLeftIcon, XFilledIcon } from "../../icons";

interface CustomDrawerProps extends DrawerProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  btnRef?: React.RefObject<HTMLButtonElement>;
  drawerHeader?: string;
  /**
   * @default false
   */
  closeOnEsc?: boolean;
  /**
   * @default false
   */
  closeOnOverlayClick?: boolean;
  /**
   * @default 500px
   */
  drawerMaxWidth?: number | string;
  /**
   * @default onClose
   */
  onBackButtonClick?: () => void;
}

export default function CustomDrawer({
  btnRef,
  isOpen,
  onClose,
  drawerHeader,
  children,
  closeOnEsc = false,
  closeOnOverlayClick = false,
  drawerMaxWidth = "500px",
  onBackButtonClick = onClose,
  ...rest
}: CustomDrawerProps) {
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const onEsc = () => {
    closeButtonRef.current?.focus();
  };

  const onOverlayClick = () => {
    const closeButton = closeButtonRef.current;

    if (closeButton) {
      closeButton.focus();

      // closeButton.focus() alone does not apply styling here.
      // Styling is applied manually to provide visual feedback.
      closeButton.style.boxShadow = "0 0 0 3px rgba(20, 204, 82, 0.6)";

      setTimeout(() => {
        closeButton.style.boxShadow = "";
      }, 1500);
    }
  };

  return (
    <Box pos="relative">
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        closeOnEsc={closeOnEsc}
        closeOnOverlayClick={closeOnOverlayClick}
        onEsc={onEsc}
        onOverlayClick={onOverlayClick}
        {...rest}
      >
        <DrawerOverlay />
        <DrawerContent
          sx={{
            m: { base: 0, md: 4 },
            borderRadius: { base: 0, md: "24px" },
            maxW: drawerMaxWidth,
            pb: "96px",
          }}
        >
          <DrawerCloseButton
            ref={closeButtonRef}
            sx={{ mt: 2, mr: 3.5 }}
            _hover={{ backgroundColor: "ash.100" }}
          >
            <XFilledIcon />
          </DrawerCloseButton>
          {drawerHeader && (
            <DrawerHeader
              sx={{ borderBottom: "1px solid #ebedef", height: "65px" }}
            >
              <Box display="flex" gap={4} alignItems="center" mt="-1">
                <IconButton
                  onClick={onBackButtonClick}
                  aria-label="Go Back"
                  variant="unstyled"
                  icon={<ArrowLeftIcon />}
                />
                <Text
                  fontWeight={500}
                  fontSize="1.125rem"
                  lineHeight="178%"
                  fontFamily="Aeonik"
                >
                  {drawerHeader}
                </Text>
              </Box>
            </DrawerHeader>
          )}
          <DrawerBody>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}
