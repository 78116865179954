import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
} from "@chakra-ui/react";

import { XFilledIcon } from "../../icons";

interface CustomModalProps extends ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  modalHeader?: string;
  btnRef?: React.RefObject<HTMLButtonElement>;
  /**
   * @default 500px
   */
  modalMaxWidth?: number | string;
}

export default function CustomModal({
  btnRef,
  isOpen,
  onClose,
  modalHeader,
  children,
  modalMaxWidth = "500px",
}: CustomModalProps) {
  return (
    <Box pos="relative">
      <Modal
        finalFocusRef={btnRef}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          sx={{
            maxW: modalMaxWidth,
            p: 6,
            pb: "96px",
            borderRadius: "24px",
            mx: { base: 4 },
          }}
        >
          {ModalHeader && <ModalHeader>{modalHeader}</ModalHeader>}
          <ModalCloseButton
            sx={{ mt: 2, mr: 3.5 }}
            _hover={{ backgroundColor: "ash.100" }}
          >
            <XFilledIcon />
          </ModalCloseButton>
          <ModalBody>{children}</ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}
