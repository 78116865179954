import { PinInputField, PinInputFieldProps } from "@chakra-ui/react";

interface CustomPinInputFieldProps extends PinInputFieldProps {
  /**
   * @default otp
   */
  pinInputVariant?: "otp" | "transactionPin";
}

const CustomPinInputField = ({
  pinInputVariant = "otp",
  ...props
}: CustomPinInputFieldProps) => {
  return (
    <PinInputField
      width={pinInputVariant === "otp" ? 103 : 160}
      height={55}
      borderRadius={14}
      borderColor="ash.200"
      color="gray.500"
      fontFamily="Aeonik"
      fontWeight={500}
      fontSize={pinInputVariant === "otp" ? "initial" : "40px"}
      _focus={{
        borderColor: "primaryGreen",
        boxShadow: "0 0 0 1px #14cc52",
      }}
      _placeholder={{
        color: "gray.200",
      }}
      _placeholderShown={{
        fontSize: "initial",
      }}
      {...props}
    />
  );
};

export default CustomPinInputField;
