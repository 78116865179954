import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IApiResponseError, IGenericApiState } from "../../types/api";

interface IRefundTransactionResponse {
  status: boolean;
  message: string;
  statusCode: string;
  errors: IApiResponseError[];
}

const initialState: IGenericApiState<IRefundTransactionResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const refundTransactionAsync = createAsyncThunk(
  "refundTransaction",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await api.post(`/virtual-account/transactions/refund`, {
        transactionId: id,
      });

      return data;
    } catch (err) {
      const error = err as AxiosError<IRefundTransactionResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const refundTransactionSlice = createSlice({
  name: "refundTransaction",
  initialState,
  reducers: {
    refundTransactionReset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(refundTransactionAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(refundTransactionAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IRefundTransactionResponse;
      })
      .addCase(refundTransactionAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IRefundTransactionResponse;
        state.error = action.error.message;
      });
  },
});

export const { refundTransactionReset } = refundTransactionSlice.actions;

export default refundTransactionSlice.reducer;
