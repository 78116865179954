import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";

import api from "../../api/axios";
import { IApiResponseError, IGenericApiState } from "../../types/api";

interface IResendNotificationResponse {
  status: boolean;
  message: string;
  statusCode: string;
  errors: IApiResponseError[];
}

const initialState: IGenericApiState<IResendNotificationResponse> = {
  data: null,
  status: "idle",
  error: "",
};

export const resendNotificationAsync = createAsyncThunk(
  "resendNotification",
  async (id: string, { rejectWithValue }) => {
    try {
      const { data } = await api.post(
        `/virtual-account/transactions/resend-notification`,
        { transactionId: id }
      );

      return data;
    } catch (err) {
      const error = err as AxiosError<IResendNotificationResponse>;
      return rejectWithValue(error.response?.data);
    }
  }
);

export const resendNotificationSlice = createSlice({
  name: "resendNotification",
  initialState,
  reducers: {
    resendNotificationReset: (state) => {
      state.data = null;
      state.status = "idle";
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resendNotificationAsync.pending, (state) => {
        state.status = "loading";
        state.error = "";
      })
      .addCase(resendNotificationAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload as IResendNotificationResponse;
      })
      .addCase(resendNotificationAsync.rejected, (state, action) => {
        state.status = "failed";
        state.data = action.payload as IResendNotificationResponse;
        state.error = action.error.message;
      });
  },
});

export const { resendNotificationReset } = resendNotificationSlice.actions;

export default resendNotificationSlice.reducer;
