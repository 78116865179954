import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import api from "../../api/axios";
import { RootState } from "..";

export interface IPasswordResetRequest {
  email: string;
}

export interface IRequestResetState {
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: string;
}

const initialState: IRequestResetState = {
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: "",
};

export const requestPasswordResetAsync = createAsyncThunk(
  "passwordReset/request",
  async (requestResetData: IPasswordResetRequest, { rejectWithValue }) => {
    try {
      const response = await api.post(
        "/auth/password/request-reset",
        requestResetData
      );
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      throw error;
    }
  }
);

export const passwordResetRequestSlice = createSlice({
  name: "passwordReset/request",
  initialState,
  reducers: {
    resetPasswordResetRequestState: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestPasswordResetAsync.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.error = "";
      })
      .addCase(requestPasswordResetAsync.fulfilled, (state) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.error = "";
      })
      .addCase(requestPasswordResetAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.error = action.payload as string;
      });
  },
});

export const { resetPasswordResetRequestState } =
  passwordResetRequestSlice.actions;

export const selectPasswordResetRequestState = (state: RootState) =>
  state.passwordResetRequest;

export default passwordResetRequestSlice.reducer;
