import { Button, ButtonProps } from "@chakra-ui/react";

export interface PrimaryButtonProps extends ButtonProps {
  children: string;
  sx?: React.CSSProperties;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  children,
  sx,
  ...rest
}) => {
  return (
    <Button
      sx={{
        background: "gray.500",
        height: "48px",
        border: "1px solid #1a1c1f",
        boxShadow: "inset 0px 2px 0px rgba(234, 234, 234, 0.25)",
        borderRadius: "12px",
        color: "white",
        fontWeight: "400",
        lineHeight: "23px",
        letterSpacing: "-0.12px",
        "&:disabled": {
          background: "ash.200",
          color: "ash.600",
          opacity: 1,
          border: "initial",
        },
        "&:hover": {
          background: "gray.500",
          opacity: 0.98,
        },
        ...sx,
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PrimaryButton;
